import React from "react";
import { Navbar } from "../../../components/Navbar";
import { Div } from "../../../styled/style";
import { Header } from "../../../components/Header";
import { Footer } from "../../../components/Footer";
import { HolidayCalenderBody } from "./style";

function HolidayCalender() {
  return (
    <Div style={{ flexDirection: "column" }}>
      <Header />
      <Navbar heading="Holiday Calender"/>
      <HolidayCalenderBody>
        <Div
          style={{
            alignItems: "flex-end",
            marginLeft: "auto",
          }}
        >
          <select style={{ fontSize: "18px", padding: "8px 15px 8px 8px" }}>
            <option>2022</option>
            <option>2023</option>
            <option>2024</option>
            <option>2025</option>
          </select>
        </Div>

        <Div>
          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>JAN 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px",
              
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>Feb 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column", }}>
              <p style={{ fontWeight: "bold" }}>MAR 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>APR 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>

        <Div>
          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>MAY 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px",
              
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>JUN 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column", }}>
              <p style={{ fontWeight: "bold" }}>JUL 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>AUG 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>



        <Div>
          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>SEP 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px",
              
            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>OCT 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column", }}>
              <p style={{ fontWeight: "bold" }}>NOV 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>

          <Div
            style={{
              border: "1px solid pink",
              padding: "5px",
              width: "300px",
              height: "300px",
              margin: "10px"

            }}
          >
            <Div style={{ flexDirection: "column" }}>
              <p style={{ fontWeight: "bold" }}>DEC 2023</p>
              <Div>
                <Div style={{ width: "20px", marginRight: "40px" }}>
                  <p style={{ fontWeight: "bold" }}>26 Thu</p>
                </Div>
                <Div
                  style={{ flexDirection: "column", justifyContent: "center" }}
                >
                  <p style={{ fontWeight: "bold" }}>Republic Day</p>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </HolidayCalenderBody>
      <Footer />
    </Div>
  );
}

export default HolidayCalender;
