import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/signIn/authSlice';
import userReducer from '../features/signIn/userSlice';
import alertReducer from '../features/signIn/AlertSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    alert: alertReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch