import React from 'react'
import { Foot, FooterAnchor } from './style'

function Footer() {
  return (
    <Foot>
      <FooterAnchor>
        v1.0.0 
      </FooterAnchor>
      <FooterAnchor>
        Privacy Policy  
      </FooterAnchor>

      <FooterAnchor>
        Terms Of Service 
      </FooterAnchor>
    </Foot>
  )
}

export default Footer