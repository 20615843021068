import { IoMdDownload } from "react-icons/io";
import { Div } from "../../../styled/style";
import { AllowanceResponse, getUploadFile } from "../../../utils/userApi";
import { formateDate } from "../../../utils/utils";
import { Dot } from "../../Employee/components/style";
import { Table, Td, Th, Tr } from "./style";

const downloadDocs = async (urls: string[]) => {
  await Promise.all(
    urls.map(async (url) => {
      let signedUrl = await getUploadFile(url);
      window.open(signedUrl, "_blank");
    })
  );
};

const AllowanceHistory = (props: {
  leaveList: AllowanceResponse[] | undefined;
}) => {
  return (
    <div>
      <h1>Allowance History</h1>
      <Table>
        <thead>
          <Tr>
            <Th>allowance</Th>
            <Th>allowance Type</Th>

            <Th>date</Th>
            <Th>Created on</Th>
            <Th>status</Th>
            <Th>payment</Th>
            <Th>remarks</Th>
            <Th>Bill</Th>
            <Th>User Remarks</Th>
          </Tr>
        </thead>
        <tbody>
          {props.leaveList &&
            props.leaveList.map((el) => (
              <Tr>
                <Td>{el.allowance}</Td>
                <Td>{el.allowanceType}</Td>

                <Td>{formateDate(new Date(el.date))}</Td>

                <Td>{formateDate(new Date(el.createdAt))}</Td>
                <Td>
                  {el.status == 0
                    ? "pending"
                    : el.status == 1
                    ? "accepted"
                    : "rejected"}
                </Td>

                <Td>
                  <Div style={{ justifyContent: "center", cursor: "pointer" }}>
                    {el.payment === 1 ? (
                      <Dot style={{ backgroundColor: "green" }} />
                    ) : (
                      <Dot style={{ backgroundColor: "red" }} />
                    )}
                  </Div>
                </Td>
                <Td>{el.remark}</Td>
                <Td>
                  <Div
                    style={{ justifyContent: "center", cursor: "pointer" }}
                    onClick={async () => {
                      await downloadDocs(el.documents);
                    }}
                  >
                    <IoMdDownload color="#ef6262" />
                  </Div>
                </Td>
                <Td>{el.userRemark}</Td>
              </Tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllowanceHistory;
