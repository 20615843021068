import React, { useEffect, useState } from "react";
import { Employee } from "../../utils/userApi";
import {
  Button,
  FormContainer,
  InputField,
  InputLabel,
  SelectField,
  ToggleInput,
  ToggleLabel,
} from "./style";
import { useQuery, UseMutateAsyncFunction } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getBands } from "../../utils/BandsApi";
import axios, { AxiosError } from "axios";
import ErrorsList from "../Errors/ErrorsList";
import { Div } from "../../styled/style";
import { LiaWalletSolid } from "react-icons/lia";
import { AllowanceAmountList } from "../AllowanceAmountList";

function formatDateForInput(date: any) {
  const formattedDate = new Date(date).toISOString().slice(0, 10);
  return formattedDate;
}

const UpdateEmployee = ({
  user,
  updateEmployeeMutation,
}: {
  user: Employee;
  updateEmployeeMutation: UseMutateAsyncFunction<
    Employee,
    unknown,
    Employee,
    unknown
  >;
}) => {
  const [formData, setFormData] = useState<Employee>(user);
  const auth = useSelector((state: RootState) => state.auth);
  const [formError, setFormError] = useState<any[]>([]);
  const [showAllowanceBallance, setShowAllowanceBallance] = useState(false);

  const { data: allBands, isLoading: bandsLoading } = useQuery({
    queryFn: () => {
      return getBands(auth.jwt);
    },
    queryKey: ["allBands"],
  });

  useEffect(() => {
    // Format the "joiningDate" when the component mounts
    setFormData((prevData) => ({
      ...prevData,
      joiningDate: formatDateForInput(user.joiningDate),
    }));
  }, [user]);

  // Handle form field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const putEmployee = await updateEmployeeMutation(formData);
      setFormError([]);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
      }
    }
    // TODO: Handle form submission, e.g., send data to the server
  };

  const handleStatusToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      status: prevData.status === 1 ? 0 : 1,
    }));
  };

  const [selectedBand, setSelectedBand] = useState("");

  const setLeave = (data:{
    month: string;
    year: string;
    amount: number;
  }[])=>{
    setFormData((prev)=>(
      {
        ...prev,
        AllowanceBallance: data
      }
    ))
  }

  const handleSelectBand = (e: any) => {
    setSelectedBand(e.target.value);
    setFormData((prev) => ({
      ...prev,
      Band: e.target.value,
    }));
  };
  return (
    <FormContainer>
      <h2>User Employee Details</h2>
      <form onSubmit={handleSubmit}>
        <InputLabel>Role</InputLabel>
        <InputField
          type="text"
          name="role"
          value={formData.role.toString()}
          onChange={handleInputChange}
        />

        <InputLabel>Email</InputLabel>
        <InputField
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
        />

        <InputLabel>Password</InputLabel>
        <InputField
          type="password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
        />

        <InputLabel>Joining Date</InputLabel>
        <InputField
          type="date"
          name="joiningDate"
          value={formData.joiningDate}
          onChange={handleInputChange}
        />

        <InputLabel>Official Email</InputLabel>
        <InputField
          type="text"
          name="officialEmail"
          value={formData.officialEmail}
          onChange={handleInputChange}
        />

        <InputLabel>employeeId</InputLabel>
        <InputField
          type="number"
          name="employeeId"
          value={formData.employeeId}
          onChange={handleInputChange}
        />

        <InputLabel>Band</InputLabel>
        <InputField
          type="text"
          name="Band"
          value={(formData.Band && typeof formData.Band !=='string' )? formData.Band.Bands:''}
          onChange={handleInputChange}
          style={{}}
          readOnly
        />

        <InputLabel>Select a Band:</InputLabel>
        {allBands ? (
          <SelectField value={selectedBand} onChange={handleSelectBand}>
            <option value="">Select a band</option>
            {allBands &&
              allBands.map((band) => (
                <option key={band.id} value={band.id}>
                  {band.Bands}
                </option>
              ))}
          </SelectField>
        ) : (
          <></>
        )}

        <ToggleLabel>
          Status
          <ToggleInput
            type="checkbox"
            name="status"
            checked={formData.status === 1}
            onChange={handleStatusToggle}
          />
        </ToggleLabel>

        <InputLabel>Leave</InputLabel>

        <Div>
          <Div style={{ flexDirection: "column", paddingRight: "10px" }}>
            <InputLabel>Sick Leave</InputLabel>

            <InputField
              type="number"
              name="employeeId"
              value={formData.leave.sickLeave}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  leave: {
                    ...prev.leave,
                    sickLeave: Number(e.target.value),
                  },
                }));
              }}
            />
          </Div>

          <Div style={{ flexDirection: "column", paddingRight: "10px" }}>
            <InputLabel>Earned Leave</InputLabel>

            <InputField
              type="number"
              name="earnedLeave"
              value={formData.leave.earnedLeave}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  leave: {
                    ...prev.leave,
                    earnedLeave: Number(e.target.value),
                  },
                }));
              }}
            />
          </Div>
          <Div style={{ flexDirection: "column" }}>
            <InputLabel>Casual Leave</InputLabel>

            <InputField
              type="number"
              name="casualLeave"
              value={formData.leave.casualLeave}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  leave: {
                    ...prev.leave,
                    casualLeave: Number(e.target.value),
                  },
                }));
              }}
            />
          </Div>
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <InputLabel>View Allowance's</InputLabel>

          <AllowanceAmountList data={formData.AllowanceBallance} setLeave={setLeave}/>
          
        </Div>
        {/* Add more fields for other properties in UserAttrs */}
        <ErrorsList error={formError} />

        <Button type="submit">Update</Button>
      </form>
    </FormContainer>
  );
};

export default UpdateEmployee;
