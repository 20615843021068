import { styled } from "styled-components";

export const LeaveApplyHeaderDiv = styled.div`
  display: flex;
  padding-bottom: 50px;
  height: calc(100vh - 40px);
  margin-bottom: 300px;
  z-index: 3
`;

export const LCHeader = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  position: fixed;
  padding-left: 380px;
  margin-top: 50px;
  background-color: #eeeded;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
  z-index:3;
`;

export const LeaveApplySidebar = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  background-color: #eeeded;
  margin-left: 120px;
  border: 2px solid white;
  z-index: 4;
  flex-direction: column;
  width: 250px;
  padding: 80px 40px 100px 120px;
`;

export const LeaveApplyButton = styled.div`
  display: flex;
  &:hover {
    font-weight: bold;
  }
  padding: 10px;
`;

export const LCHeaderButton = styled.button`
  font-size: 20px;
`;

