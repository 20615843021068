import { styled } from "styled-components";

export const AllowanceRequestsBody = styled.div`
  margin-left: 300px;
  margin-top: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;


