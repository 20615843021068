import React from "react";
import { Table, Td, Th, Tr } from "./style";
import { LeaveList } from "../../../utils/userApi";
import { formateDate } from "../../../utils/utils";

const LeaveTransactionHistory = (props: { leaveList: LeaveList[]| undefined }) => {
  return (
    <div>
      <h1>Transaction History</h1>
      <Table>
        <thead>
          <Tr>
            <Th>Leave Type</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>reason</Th>
            <Th>status</Th>
          </Tr>
        </thead>
        <tbody>
          {props.leaveList && props.leaveList.map((el) => (
            <Tr>
              <Td>{el.leaveType}</Td>
              <Td>{formateDate(new Date(el.fromDate)) }</Td>
              <Td>{formateDate(new Date(el.toDate)) }</Td>
              <Td>{el.reason}</Td>
              <Td>{el.status == 0?"pending":el.status == 1?"accepted": "rejected"}</Td>
            </Tr>
          ))}

        </tbody>
      </Table>
    </div>
  );
};

export default LeaveTransactionHistory;
