import React, { useState } from "react";
import { Button, FormWrapper, Input, Label } from "./style";
import { Div } from "../../../styled/style";

const VendorRegistrationForm = () => {
  const [formData, setFormData] = useState({
    vendorName: "",
    paymentInFavourOf: "",
    natureOfGoodsOrServices: "",
    address: {
      street: "",
      street2: "",
      street3: "",
      city: "",
      postalCode: "",
      country: "India",
    },
    telephoneNo: "",
    faxNo: "",
    emailID: "",
    contactPerson: {
      name: "",
      telephoneNo: "",
    },
    serviceTaxNo: "",
    tinOrGSTNo: "",
    panNo: "",
    bankDetails: {
      bankName: "",
      accountNo: "",
      ifscCode: "",
      swiftBIC: "",
    },
    tptStatus: "N",
    registrationForm: "",
    cancelledChequeCopy: "",
    ethicsCertificate: "",
    bankDetailsDocument: {
      beneficiaryName: "",
      bankAndBranchName: "",
      bankAddress: "",
      bankTelephoneNos: "",
      neftIFSCCode: "",
      eftMICRCode: "",
      accountType: "",
      accountNo: "",
      bankEmailID: "",
    },
    gstCertificate: "",
    msmesRegistrationCertificate: "",
    moaAndPoa: "",
    agreementCopy: "",
    firmRegistrationCopy: "",
  });

  // Handle form input changes
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., send data to the server)
    console.log(formData);
  };

  return (
    <FormWrapper>
        <h2>Vendor Registration</h2>
      <form onSubmit={handleSubmit}>
        <Label>Vendor Name:</Label>
        <Input
          type="text"
          id="vendorName"
          name="vendorName"
          value={formData.vendorName}
          onChange={handleInputChange}
        />

        <Label>Payment in Favor of:</Label>
        <Input
          type="text"
          id="paymentInFavourOf"
          name="paymentInFavourOf"
          value={formData.paymentInFavourOf}
          onChange={handleInputChange}
        />

        <Label>Nature of Goods/Services:</Label>
        <Input
          type="text"
          id="natureOfGoodsOrServices"
          name="natureOfGoodsOrServices"
          value={formData.natureOfGoodsOrServices}
          onChange={handleInputChange}
        />

        <Label>Address:</Label>
        <Input
          type="text"
          id="street"
          name="address.street"
          placeholder="street"
          value={formData.address.street}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="street2"
          name="address.street2"
          placeholder="street2"
          value={formData.address.street2}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="street3"
          placeholder="street3"
          name="address.street3"
          value={formData.address.street3}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="city"
          name="address.city"
          placeholder="city"
          value={formData.address.city}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="postalCode"
          placeholder="postal code"
          name="address.postalCode"
          value={formData.address.postalCode}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="country"
          placeholder="country"
          name="address.country"
          value={formData.address.country}
          onChange={handleInputChange}
        />

        {/* Add the contact person fields */}
        <Label>Contact Person Name:</Label>
        <Input
          type="text"
          id="contactPerson.name"
          name="contactPerson.name"
          value={formData.contactPerson.name}
          onChange={handleInputChange}
        />
        <Label>Contact Person Telephone No:</Label>
        <Input
          type="text"
          id="contactPerson.telephoneNo"
          name="contactPerson.telephoneNo"
          value={formData.contactPerson.telephoneNo}
          onChange={handleInputChange}
        />

        {/* Continue adding the remaining fields */}
        <Label>Service Tax No:</Label>
        <Input
          type="text"
          id="serviceTaxNo"
          name="serviceTaxNo"
          value={formData.serviceTaxNo}
          onChange={handleInputChange}
        />

        <Label>TIN No/GST No:</Label>
        <Input
          type="text"
          id="tinOrGSTNo"
          name="tinOrGSTNo"
          value={formData.tinOrGSTNo}
          onChange={handleInputChange}
        />

        {/* Add the bank details fields */}
        <Label>Name of the Bank & Branch:</Label>
        <Input
          type="text"
          id="bankDetails.bankName"
          placeholder="bankName"
          name="bankDetails.bankName"
          value={formData.bankDetails.bankName}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="bankDetails.accountNo"
          placeholder="accountNo"
          name="bankDetails.accountNo"
          value={formData.bankDetails.accountNo}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="bankDetails.ifscCode"
          placeholder="ifscCode"
          name="bankDetails.ifscCode"
          value={formData.bankDetails.ifscCode}
          onChange={handleInputChange}
        />
        <Input
          type="text"
          id="bankDetails.swiftBIC"
          placeholder="swiftBIC"
          name="bankDetails.swiftBIC"
          value={formData.bankDetails.swiftBIC}
          onChange={handleInputChange}
        />

        <Div>
          <Div style={{ flexDirection: "column", paddingRight: "100px" }}>
            <Label htmlFor="cancelledChequeCopy">Cancelled Cheque Copy:</Label>
            <Input
              type="file"
              id="cancelledChequeCopy"
              name="cancelledChequeCopy"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
          <Div style={{ flexDirection: "column" }}>
            <Label htmlFor="ethicsCertificate">
              Ethics & Business Conduct Certificate:
            </Label>
            <Input
              type="file"
              id="ethicsCertificate"
              name="ethicsCertificate"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
        </Div>

        <Div>
          <Div style={{ flexDirection: "column", paddingRight: "100px" }}>
            <Label htmlFor="gstCertificate">Certification of GST:</Label>
            <Input
              type="file"
              id="gstCertificate"
              name="gstCertificate"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
          <Div style={{ flexDirection: "column" }}>
            <Label htmlFor="msmesRegistrationCertificate">
              MSMED Registration Certificate:
            </Label>
            <Input
              type="file"
              id="msmesRegistrationCertificate"
              name="msmesRegistrationCertificate"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
        </Div>

        <Div>
          <Div style={{ flexDirection: "column", paddingRight: "100px" }}>
            <Label htmlFor="moaAndPoa">MOA and POA Other than Directors:</Label>
            <Input
              type="file"
              id="moaAndPoa"
              name="moaAndPoa"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
          <Div style={{ flexDirection: "column" }}>
            <Label htmlFor="agreementCopy">Agreement copy:</Label>
            <Input
              type="file"
              id="agreementCopy"
              name="agreementCopy"
              accept=".pdf, .doc, .docx"
              onChange={handleInputChange}
            />
          </Div>
        </Div>

        <Label htmlFor="firmRegistrationCopy">Firm Registration Copy:</Label>
        <Input
          type="file"
          id="firmRegistrationCopy"
          name="firmRegistrationCopy"
          accept=".pdf, .doc, .docx"
          onChange={handleInputChange}
        />

        <Button type="submit">Submit</Button>
      </form>
    </FormWrapper>
  );
};

export default VendorRegistrationForm;
