import React, { useState } from 'react'
import { ModalBody, ModalInput, ModalSubmitButton, SelectField } from './Style'
import {RxCross1} from 'react-icons/rx';
import { Div } from '../../../../styled/style';
import { assignEmail } from '../../../../utils/userApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { handleAxiosError } from '../../../../utils/utils';
import { useQuery } from '@tanstack/react-query';
import { getBands } from '../../../../utils/BandsApi';

function AssignEmailModal(props: any) {
  const [email, setEmail] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [selectedBand, setSelectedBand] = useState("");

  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const { data: allBands, isLoading: bandsLoading } = useQuery({
    queryFn: () => {
      return getBands(auth.jwt);
    },
    queryKey: ["allBands"],
  });

  async function callApi(){
    try{
      const response = await assignEmail({email:email, trackerId: props.trackerId, employeeId: employeeId, bandId: selectedBand}, auth.jwt);
      console.log(response);
      props.getAllTrackers();
      props.onClick();
    }catch(err){
      console.log(err);
      handleAxiosError(err, dispatch);
    }
  }
  return (
    <ModalBody>
        <Div>
          <RxCross1 size={100} style={{marginLeft:'auto', cursor:'pointer', color:'red'}} onClick={props.onClick}/>
        </Div>
        <h1>Official Email</h1>
        <ModalInput onChange={(e)=>setEmail(e.target.value)} placeholder='official email' />
        <h1>Employee Id</h1>
        <ModalInput onChange={(e)=>setEmployeeId(e.target.value)} placeholder='employee id' />

        <h1>Band</h1>
        {allBands ? (
          <SelectField value={selectedBand} onChange={(e)=>setSelectedBand(e.target.value)}>
            <option value="">Select a band</option>
            {allBands &&
              allBands.map((band) => (
                <option key={band.id} value={band.id}>
                  {band.Bands}
                </option>
              ))}
          </SelectField>
        ) : (
          <></>
        )}
        <ModalSubmitButton onClick={callApi}>submit</ModalSubmitButton>
    </ModalBody>
  )
}

export default AssignEmailModal