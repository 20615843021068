import React, { useEffect, useState } from "react";
import {
  Table,
  Td,
  Th,
  Tr,
  TrackerBodyDiv,
  TrackerBodyStyle,
  
} from "./style";
import { getTrackerRequest } from "../../../../utils/TrackerApi";
import { Div } from "../../../../styled/style";
import { GrDocumentUpdate } from "react-icons/gr";
import { AiOutlineMail } from "react-icons/ai";
import Modal from "../Modal/AssignEmailModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { formateDate, handleAxiosError } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../config/route.config";

interface tracker {
  id: string;
  personalDetails: {
    I: {
      name: string;
      DOB: string;
    };
    spouse: {
      name: string;
      DOB: string;
    };
    child:
      | {
          name: string;
          DOB: string;
          sex: string;
        }[]
      | [];
    pincode: string;
    country: string;
    currentAddress: string;
    permanentAddress: string;
    phoneNumber: string;
    emailId: string;
  };
  professionalDetails: {
    company: string[] | [];
  };
  educationDetails: {
    tenth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    twelfth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    graduation:
      | {
          stream: string;
          passingPercentage: string;
          passingYear: string;
          college: string;
        }[]
      | [];
  };
  references:
    | {
        name: string;
        phoneNumber: string;
      }[]
    | [];

  identity: {
    uanNumber: string;
    panNumber: string;
    passport: string;
    aadharNumber: string;
  };
  bankAccountDetails: {
    bankName: string;
    address: string;
    accountNumber: string;
    ifscCode: string;
    accountHolderName: string;
  };
  createdAt: string;
  updatedAt: string;
}

async function callAssignEmailApi() {}
function TrackerList() {
  const [allTrackers, setAllTrackers] = useState<tracker[] | null>(null);
  const [trackerId, setTrackerId] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const handleUpdateTracker = (trackerId: string) => {
    console.log(trackerId);
    navigate(`${RoutePath.Tracker}?trackerId=${trackerId}`);

  };

  const handleAssignEmail = (trackerId: string) => {
    setAssignEmail(!assignEmail);
    setTrackerId(trackerId);
  };
  async function getAllTrackers() {
    try {
      const getData = await getTrackerRequest(auth.jwt);
      setAllTrackers(getData);
    } catch (err) {
      console.log(err);
      handleAxiosError(err, dispatch);
    }
  }
  useEffect(() => {
    getAllTrackers();
  }, [auth]);

  const [assignEmail, setAssignEmail] = useState(false);

  return (
    <TrackerBodyStyle>
      <TrackerBodyDiv>
        {assignEmail ? (
          <Modal
            getAllTrackers={getAllTrackers}
            trackerId={trackerId}
            onClick={() => {
              setAssignEmail(false);
            }}
          />
        ) : (
          <Table style={{ width: "100%" }}>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Phone Number</Th>
              <Th>Created At</Th>
              <Th>Updated At</Th>
              <Th>Aadhar Number</Th>
              <Th>Pan number</Th>
              <Th>passport</Th>
              <Th>Update Tracker</Th>
              <Th>Assign Email</Th>
            </Tr>

            {allTrackers?.map((item) => (
              <Tr>
                <Td>{item.personalDetails.I.name}</Td>
                <Td>{item.personalDetails.emailId}</Td>
                <Td>{item.personalDetails.phoneNumber}</Td>
                <Td style={{ whiteSpace: "nowrap" }}>
                  {formateDate(new Date(item.createdAt))}
                </Td>
                <Td style={{ whiteSpace: "nowrap" }}>
                  {formateDate(new Date(item.updatedAt))}
                </Td>
                <Td>{item.identity.aadharNumber}</Td>
                <Td>{item.identity.panNumber}</Td>
                <Td>{item.identity.passport}</Td>

                <Td
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleUpdateTracker(item.id);
                  }}
                >
                  <GrDocumentUpdate />
                </Td>

                <Td
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleAssignEmail(item.id);
                  }}
                >
                  <AiOutlineMail />
                </Td>
              </Tr>
            ))}
          </Table>
        )}
      </TrackerBodyDiv>
    </TrackerBodyStyle>
  );
}

export default TrackerList;
