import React, { useEffect, useMemo, useState } from "react";
import { GiPayMoney } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import {
  BodyDiv,
  BodyStyle,
  Card,
  CardHeading,
  Center,
  ColumnOne,
  ColumnThree,
  ColumnTwo,
  QuickAccessLinks,
  QuickAccessTable,
} from "./style";
import { GrNotes } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getUserData, swipe } from "../../../../utils/userApi";
import { handleAxiosError } from "../../../../utils/utils";
import { setName } from "../../../../features/signIn/userSlice";
import { setAlert } from "../../../../features/signIn/AlertSlice";
import { Spinner } from "../../../../components/Spinner";
import { PayslipList } from "../../../PayslipList";

function HomeBody() {
  const [time, setTime] = useState(new Date());
  const [signedIn, setSignedIn] = useState(false);
  const [formattedDate, setFormattedDate] = useState<any>("18 July 2023");
  const [formattedDay, setFormattedDay] = useState<any>("Tuesday");

  useMemo(() => {
    const date = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const dayOfWeek = daysOfWeek[date.getDay()];

    setFormattedDate(`${day} ${month} ${year} `);
    setFormattedDay(`${dayOfWeek}`);
  }, [time]);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const auth = useSelector((state: RootState) => state.auth);

  const { data: user, isLoading } = useQuery({
    queryFn: () => getUserData(auth.jwt),
    queryKey: ["user"],
  });

  useEffect(() => {
    if (user) {
      dispatch(setName(user));
    }
  }, [user, dispatch]);


  useEffect(() => {
    if (user?.signIn?.online) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [user]);

  if (isLoading) {
    return <Spinner />;
  }

  const formatTimeUnit = (timeUnit: any) => {
    return timeUnit < 10 ? `0${timeUnit}` : timeUnit;
  };

  const hours = formatTimeUnit(time.getHours());
  const minutes = formatTimeUnit(time.getMinutes());
  const seconds = formatTimeUnit(time.getSeconds());

  async function handleSignin() {
    try {
      setSignedIn(!signedIn);
      queryClient.invalidateQueries(["user"]);
      await swipe(auth.jwt);
      dispatch(setAlert({ message: "Done!", visible: true }));
    } catch (err) {
      console.log(err);
      handleAxiosError(err, dispatch);
    }
  }

  return (
    <BodyStyle>
      <BodyDiv>
        <ColumnOne>
          <Card style={{ flexGrow: 4 }}>
            <CardHeading>Review</CardHeading>
            <Center>
              <GrNotes
                size={100}
                style={{ marginTop: "20px", marginBottom: "30px" }}
              />
              <p>Hurrah! You've nothing to review. </p>
            </Center>
          </Card>

          <Card style={{ flexGrow: 1 }}>
            <CardHeading>Quick Access</CardHeading>
            <QuickAccessTable style={{ height: "100%" }}>
              <QuickAccessTable
                style={{ flexDirection: "column", width: "100%" }}
              >
                <QuickAccessLinks>
                  <p>IT Statement</p>
                </QuickAccessLinks>
                <QuickAccessLinks>
                  <p>YTD Reports</p>
                </QuickAccessLinks>
                <QuickAccessLinks>
                  <p>Loan Statement</p>
                </QuickAccessLinks>
              </QuickAccessTable>
              <QuickAccessTable
                style={{
                  flexDirection: "column",
                  backgroundColor: " #FF6666",
                  borderRadius: "10px 0 10px 0px",
                  padding: "5px",
                }}
              >
                <p style={{ color: "white" }}>
                  Use quick access to view important salary details.
                </p>
              </QuickAccessTable>
            </QuickAccessTable>
          </Card>

          <Card style={{ flexGrow: 4 }}>
            <CardHeading style={{ flexGrow: 4 }}>Track</CardHeading>
            <QuickAccessTable>
              <Card style={{ marginRight: "10px", marginLeft: "10px" }}>
                <CardHeading>02</CardHeading>
                <p style={{ paddingLeft: "10px" }}>Attendance Regulation</p>
              </Card>
              <Card
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <CardHeading>02</CardHeading>
                <p style={{ paddingLeft: "10px" }}>Attendance Regulation</p>
              </Card>
            </QuickAccessTable>
          </Card>
        </ColumnOne>

        <ColumnTwo>
          <Card style={{ flexGrow: 0 }}>
            <CardHeading style={{ fontWeight: "bold" }}>
              {formattedDate}
            </CardHeading>
            <CardHeading>{formattedDay} | General</CardHeading>
            <CardHeading style={{ fontWeight: "bold" }}>
              {hours}:{minutes}:{seconds}
            </CardHeading>
            <button
              style={{
                backgroundColor: "#FF6666",
                borderRadius: "0px 0px 10px 10px",
                color: "white",
                border: "none",
              }}
              onClick={handleSignin}
            >
              <p>{signedIn ? "Sign Out" : "Sign In"}</p>
            </button>
          </Card>

          <Card style={{ flexGrow: 2 }}>
            <CardHeading>Payslip</CardHeading>
            {user && user.payslips.length > 0 ? (
              <PayslipList employee={user} />
            ) : (
              <Center>
                <GiPayMoney
                  size={100}
                  style={{ marginTop: "20px", marginBottom: "30px" }}
                />
                <p>
                  Uh oh! Your Payslip will show up here after the release of
                  Payroll.
                </p>
              </Center>
            )}
          </Card>
        </ColumnTwo>

        <ColumnThree>
          <Card style={{ flexGrow: 2 }}>
            <CardHeading>Upcoming Holidays</CardHeading>
            <CardHeading>
              <p style={{ fontWeight: "bold" }}>15 Aug Tuesday</p>
              <p>Independence Day</p>
            </CardHeading>
            <CardHeading>
              <p style={{ fontWeight: "bold" }}>15 Aug Tuesday</p>
              <p>Independence Day</p>
            </CardHeading>
            <CardHeading>
              <p style={{ fontWeight: "bold" }}>15 Aug Tuesday</p>
              <p>Independence Day</p>
            </CardHeading>
          </Card>

          <Card style={{ flexGrow: 1 }}>
            <CardHeading>IT Declaration</CardHeading>
            <QuickAccessTable>
              <TbMoneybag size={100} />
              <p>
                Hold on! You can submit your Income Tax (IT) declaration once
                released.
              </p>
            </QuickAccessTable>
          </Card>
        </ColumnThree>
      </BodyDiv>
    </BodyStyle>
  );
}

export default HomeBody;
