import React, { useState } from "react";
import { LeaveApplyButton, LeaveApplyForm, VerticalLine } from "./styled";
import { Div } from "../../../../styled/style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiUser } from "react-icons/bi";
import SingleSelect from "./SingleSelect";
import MultiSelect from "./MultiSelect";
import { BsCcCircle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { applyLeave, postLeave } from "../../../../utils/LeaveApi";
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeEmailList } from "../../../../utils/userApi";
import axios, { AxiosError } from "axios";
import ErrorsList from "../../../../components/Errors/ErrorsList";
import { setAlert } from "../../../../features/signIn/AlertSlice";
import { Spinner } from "../../../../components/Spinner";

function LeaveApply() {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const queryClient = useQueryClient();

  const [form, setForm] = useState<postLeave>({
    applyTo: "",
    ccTo: [],
    contactDetails: "",
    fromDate: "",
    leaveType: "",
    reason: "",
    toDate: "",
  });

  const [info, setInfo] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [formError, setFormError] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const applyLeaveHandle = async () => {
    setLoading(true);
    setForm((prev) => {
      prev.jwt = auth.jwt;
      return prev;
    });

    const res = await applyLeave(form);
    return res;
    
  };

  const { mutateAsync: applyLeaveMutation } = useMutation({
    mutationFn: async () => await applyLeaveHandle(),
    onSuccess: () => {
      setLoading(false);
      dispatch(setAlert({message:'Leave Applied', visible:true}));
      setFormError([]);
    },
    onError: (err) => {
      setLoading(false);

      dispatch(setAlert({message:'Leave failed', visible:true}));

      if(axios.isAxiosError(err)){
        const axiosError = err as AxiosError;
        let errors:any= axiosError.response?.data;
        setFormError(errors.errors);
        console.log(formError);
        
      }
    },
    
    
  });

  const { data: leaves, isLoading } = useQuery({
    queryFn: () => getEmployeeEmailList(auth.jwt),
    queryKey: ["leave"],
  });

  if (isLoading) {
    return <Spinner/>;
  }

  const handleFromDateChange = (date: any) => {
    setSelectedFromDate(date);
    setForm((prev) => {
      prev.fromDate = date;
      return prev;
    });
  };

  const handleToDateChange = (date: any) => {
    setSelectedToDate(date);
    setForm((prev) => {
      prev.toDate = date;
      return prev;
    });
  };

  return (
    <LeaveApplyForm>
      <Div style={{ padding: "5px" }}>
        {info ? (
          <p style={{ backgroundColor: "yellow" }}>
            Leave is earned by an employee and granted by the employer to take
            time off work. The employee is free to avail this leave in
            accordance with the company policy.
          </p>
        ) : (
          <></>
        )}
        <button
          style={{
            border: "none",
            backgroundColor: "white",
            color: "blue",
            marginLeft: "10px",
          }}
          onClick={() => setInfo(!info)}
        >
          {info ? "Hide" : "Info"}
        </button>
      </Div>

      <Div style={{ paddingLeft: "20px" }}>
        <p>Applying for Leave</p>
      </Div>

      <Div>
        <Div
          style={{
            flexDirection: "column",
            padding: "20px",
            marginRight: "440px",
            width: "400px",
          }}
        >
          <Div style={{ flexDirection: "column" }}>
            <p>Leave type</p>
            <select
              style={{ marginRight: "10px" }}
              onChange={(e) =>
                setForm((prev) => {
                  prev.leaveType = e.target.value;
                  return prev;
                })
              }
            >
              <option selected>Leave Type</option>
              <option value="earned-leave" >Earned Leave</option>
              <option value="sick-leave">Sick Leave</option>
              <option value="casual-leave">Leave without pay</option>
            </select>
          </Div>

          <Div style={{ flexDirection: "column" }}>
            <Div>
              <p style={{ marginRight: "105px" }}>To date</p>
              <p>Sessions</p>
            </Div>
            <Div>
              <Div style={{ marginRight: "10px" }}>
                <DatePicker
                  selected={selectedFromDate}
                  onChange={handleFromDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select a date"
                />
              </Div>
              <select style={{ marginRight: "10px" }}>
                <option value="volvo">Session 1</option>
              </select>
            </Div>
          </Div>

          <Div style={{ flexDirection: "column" }}>
            <Div>
              <p style={{ marginRight: "85px" }}>From date</p>
              <p>Sessions</p>
            </Div>
            <Div>
              <Div style={{ marginRight: "10px" }}>
                <DatePicker
                  selected={selectedToDate}
                  onChange={handleToDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select a date"
                />
              </Div>
              <select style={{ marginRight: "10px" }}>
                <option value="volvo">Session 1</option>
              </select>
            </Div>
          </Div>
        </Div>

        <Div
          style={{
            alignContent: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Div>
            <VerticalLine style={{ marginRight: "10px" }} />
            <Div>Balance: Applying for:</Div>
          </Div>
        </Div>
      </Div>
      <Div style={{ marginLeft: "17px" }}>
        <BiUser size={40} />
        <SingleSelect
          leaves={leaves}
          onChange={(e: any) =>
            setForm((prev) => {
              prev.applyTo = e ? e.value : "";
              return prev;
            })
          }
        />
      </Div>
      <Div style={{ margin: "17px" }}>
        <BsCcCircle size={40} style={{ marginRight: "5px" }} />
        <MultiSelect
          leaves={leaves}
          onChange={(e: any) =>
            setForm((prev) => {
              prev.ccTo = e;
              return prev;
            })
          }
        />
      </Div>
      <Div style={{ flexDirection: "column", margin: "17px" }}>
        <p>Contact details</p>
        <input
          style={{ marginRight: "10px", width: "200px", height: "30px" }}
          onChange={(e) =>
            setForm((prev) => {
              prev.contactDetails = e.target.value;
              return prev;
            })
          }
        />
      </Div>
      <Div style={{ flexDirection: "column", margin: "5px" }}>
        <p>Reason</p>
        <input
          style={{ marginRight: "10px", height: "80px", width: "600px" }}
          onChange={(e) =>
            setForm((prev) => {
              prev.reason = e.target.value;
              return prev;
            })
          }
        />
      </Div>

      <ErrorsList error={formError}/>

      <Div
        style={{
          justifyContent: "center",
          alignContent: "center",
          margin: "50px",
        }}
      >
        <LeaveApplyButton
          onClick={async () => {
            try {
              await applyLeaveMutation();
            } catch (err) {
              console.log(err);
            }
          }}
        >
          {!loading?'Submit':<Div style={{justifyContent:'center', alignContent:'center'}}><Spinner/></Div>}
        </LeaveApplyButton>
        <LeaveApplyButton>Cancel</LeaveApplyButton>
      </Div>
    </LeaveApplyForm>
  );
}

export default LeaveApply;
