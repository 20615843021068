import React, { useEffect, useState } from "react";
import {
  AllowanceBody,
  DropdownButton,
  DropdownContainer,
  DropdownContent,
  DropdownItem,
} from "./style";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import { Div } from "../../../styled/style";
import AddAllowanceForm from "../components/AddAllowanceForm";
import AllowanceHistory from "../components/AllowanceHistory";
import { LeaveBallanceCard } from "../../LeaveBallance/components/LeaveBallanceCard";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getAllowanceRequest, getUserData } from "../../../utils/userApi";
import { Alert } from "../../../components/Alert";
import { Spinner } from "../../../components/Spinner";
import { BiSolidDownArrow, BiSolidRightArrow, BiSolidUpArrow } from "react-icons/bi";

const UserAllowance = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Allowance");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (option: number) => {
    setAllowanceType(option)
    toggleDropdown();
  };
  const queryClient = useQueryClient();
  const auth = useSelector((state: RootState) => state.auth);
  const [allowanceType, setAllowanceType] = useState(0);
  const [date, setDate] = useState("");
  const [currentMonthAllowance, setCurrentMonthAllowance] = useState("");
  const { data: user, isLoading: userLoading } = useQuery({
    queryFn: () => {
      return getUserData(auth.jwt);
    },
    queryKey: ["user"],
  });

  const { data: allAllowancesRequest, isLoading: allowanceListLoading } =
    useQuery({
      queryFn: () => {
        return getAllowanceRequest(auth.jwt);
      },
      queryKey: ["allowanceList"],
    });

  const switchAllowance = () => {
    setAllowanceType((allowanceType + 1) % 3);
  };

  useEffect(() => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let currentDate = new Date(date);
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const foundObject = user?.AllowanceBallance.find((item) => {
      return (
        item.month === months[currentMonth] && item.year === currentYear + ""
      );
    });

    if (foundObject) {
      setCurrentMonthAllowance(foundObject.amount + "");
    } else {
      setCurrentMonthAllowance("0");
    }
  }, [date]);

  function generateCard() {
    if (allowanceType === 0) {
      return (
        <LeaveBallanceCard
          onClick={switchAllowance}
          heading="Allowance ballance"
          balance={user ? currentMonthAllowance : "-"}
          selected={true}
        />
      );
    } else if (allowanceType === 1) {
      return (
        <LeaveBallanceCard
          onClick={switchAllowance}
          heading="LTA"
          balance={user ? user.LTABallance : "-"}
          selected={true}
        />
      );
    } else {
      return (
        <LeaveBallanceCard
          onClick={switchAllowance}
          heading="Other Reimbursement"
          balance={user ? "Infinite" : "-"}
          selected={true}
        />
      );
    }
  }
  return (
    <Div style={{ flexDirection: "column" }}>
      <Navbar heading="Allowance" />
      <Header />
      <Alert />
      {userLoading || allowanceListLoading ? (
        <Spinner />
      ) : (
        <AllowanceBody>
          
          <Div style={{alignItems:'center'}}>
          <DropdownContainer>
            <DropdownButton onClick={toggleDropdown}>
              {allowanceType===0?'Allowance':allowanceType === 1?'LTA':'OTHER'}
              <BiSolidDownArrow style={{position:'absolute', top:'10px', right: '10px'}} />
            </DropdownButton>
            {isOpen ? (
              <DropdownContent>
                 <DropdownItem onClick={() => selectOption(0)}>
                  Allowance  
                </DropdownItem>
                <DropdownItem onClick={() => selectOption(1)}>
                  LTA
                </DropdownItem>
               
                <DropdownItem onClick={() => selectOption(2)}>
                  Others
                </DropdownItem>
              </DropdownContent>
            ) : (
              <></>
            )}
          </DropdownContainer>
            {generateCard()}

            <AddAllowanceForm
              allowanceType={
                allowanceType === 0
                  ? "entertainment"
                  : allowanceType === 1
                  ? "LTA"
                  : "OTHER"
              }
              date={date}
              setDate={setDate}
            />
          </Div>

          <AllowanceHistory leaveList={allAllowancesRequest} />
        </AllowanceBody>
      )}
      <Footer />
    </Div>
  );
};

export default UserAllowance;
