import { styled } from "styled-components";

export const AllowanceForm = styled.div``;

export const AllowanceInput = styled.input`
  outline: none;

  border: none;
  border-bottom: 2px solid #000;
`;
export const ApplyAllowanceButton = styled.button`
  background-color: #ef6262;
  border: none;
  color: white;
  padding: 30px;
  margin: 10px;
  border-radius: 10px;

  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
`;

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #ef6262;
  color: white;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;

export const CommentInput = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
`;