import styled from "styled-components";

export const PayslipListContainer = styled.div`
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
  overflow-y: auto;
  height: 100vh;
  height:50vh;
`;

export const PayslipItem = styled.div`
  margin-bottom: 10px;
`;

export const PayslipTitle = styled.h3`
  font-size: 1rem;
  margin-bottom: 5px;
`;

export const PayslipUrlsList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const PayslipUrlItem = styled.li`
  margin-bottom: 5px;
  font-size: 0.9rem;
`;
