import React from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'

import 'react-big-calendar/lib/css/react-big-calendar.css';

import moment from 'moment';
import { getUserAttendance } from "../../../utils/AttendanceApi";



const UserAttendanceData = (props: {attendanceData: getUserAttendance}) => {
  const events: any[] | undefined = [];

    const { absent, present, leave, employee } = props.attendanceData;
    
    // Define styles for different types of events
    const absentStyle = { backgroundColor: "red" };
    const presentStyle = { backgroundColor: "green" };
    const leaveStyle = { backgroundColor: "pink" };

    // Add absent events to the calendar
    absent.forEach((date) => {
      events.push({
        start: new Date(date),
        end: new Date(date),
        title: "Absent",
        style: absentStyle,
      });
    });

    // Add present events to the calendar
    present.forEach((date) => {
      events.push({
        start: new Date(date),
        end: new Date(date),
        title: "Present",
        style: presentStyle,
      });
    });

    // Add leave events to the calendar
    leave.forEach((date) => {
      events.push({
        start: new Date(date),
        end: new Date(date),
        title: "Leave",
        style: leaveStyle,
      });
    });
  return (
    <div style={{height:'600px', width:'600px'}}>
     <Calendar 
        onSelectEvent={(d)=>{
            console.log(d);
            
        }}
        events={events} // Your events data
        startAccessor="start"
        endAccessor="end"
        views={['month']}
        localizer={momentLocalizer(moment)}
        showMultiDayTimes
      />
    </div>
  );
};


  
export default UserAttendanceData;
