import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ef6262;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ef6262;
  border-radius: 5px;
`;

export const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ef6262;
  border-radius: 5px;
`;


export const Button = styled.button`
  padding: 10px 20px;
  background-color: #ef6262;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const ToggleLabel = styled.label`
  display: block;
  font-weight: bold;
  margin-top: 10px;
`;

export const ToggleInput = styled.input`
  margin-right: 5px;
`;