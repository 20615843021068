import React, { useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Div } from "../../../styled/style";
import {
  LoginButton,
  LoginForgetPassword,
  LoginFormBody,
  LoginInput,
  LoginMainForm,
} from "./style";
import { signIn } from "../../../utils/userApi";
import { useSelector, useDispatch } from "react-redux";
import { login, signOut } from "../../../features/signIn/authSlice";
import { RootState } from "../../../app/store";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../config/route.config";
import axios, { AxiosError } from "axios";
import ErrorsList from "../../../components/Errors/ErrorsList";

function LoginForm(props:{onForgetPasswordClick:()=>void}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [formError, setFormError] = useState<any[]>([]);

  const auth = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const onSuperAdmin = () => {
    navigate(RoutePath.AdminDashboard);
  };

  const onEmployee = () => {
    navigate(RoutePath.Home);
  };

  async function callLogin() {
    try {
      const res = await signIn({
        email: email,
        password: password,
      });

      dispatch(login({ jwt: res.token, role: res.role }));
      localStorage.setItem("jwt", res.token);
      localStorage.setItem("role", res.role);
      console.log(res.role);

      if (res.role === "SUPER_ADMIN") {
        onSuperAdmin();
      } else if (res.role === "employee") {
        onEmployee();
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
        console.log(formError);
      }
      console.log(err);
    }
  }
  return (
    <LoginFormBody>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <img src={logo} alt="" width={50} />
      </Div>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <p style={{ fontWeight: "bold" }}>Hello there! 👋</p>
      </Div>
      <LoginMainForm>
        <p>Login ID</p>
        <LoginInput
          type="text"
          placeholder="employee id"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <p>Password</p>
        <LoginInput
          type="password"
          placeholder="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <ErrorsList error={formError} />

        <Div>
          <LoginForgetPassword onClick={props.onForgetPasswordClick}>Forgot password?</LoginForgetPassword>
        </Div>

        <Div style={{ height: "40px" }}>
          <LoginButton onClick={callLogin}>Login</LoginButton>
        </Div>
      </LoginMainForm>
    </LoginFormBody>
  );
}

export default LoginForm;
