import { styled } from "styled-components";

export const EmployeeBodyStyle = styled.div`
  height: 120vh;
  margin-left: 300px;
  margin-top: 80px;
  width: 67%;
`;

export const EmployeeBodyDiv = styled.div`
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
`;

export const EmployeeListForm = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto;
  gap: 10px;
  background-color: #2196f3;
  padding: 10px;
`;

export const EmployeeDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
`;

export const EmployeeP = styled.p`
  font-weight: bold;
  font-size: 30px;
`;

export const EmployeeHeading = styled.p`
  font-weight: bold;
  font-size: 30px;
`;

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #ef6262;
  color: white;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;


export const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;
