import React from 'react'
import { Header } from '../../../components/Header';
import { Footer } from '../../../components/Footer';
import { HomeBody } from '../components/HomeBody';
import { Navbar } from '../../../components/Navbar';
import { Alert } from '../../../components/Alert';
import { Div } from '../../../styled/style';

function Home() {
  return (
    <Div style={{width: '100%',flexDirection:'column'}} >
        <Navbar heading='Home'/>
        <Header/>
        <Alert/>
        <div style={{display:'flex'}}>
        <HomeBody/>
        </div>
        <Footer/>
    </Div>
  )
}

export default Home;