import React from 'react'
import { Div } from '../../styled/style'
import { ErrorUl } from './style'

const ErrorsList = (props:{error:any[]}) => {
  return (
    <Div style={{flexDirection:"column"}}>
        <ErrorUl>
        {
            props.error&& props.error.map(el=><li style={{color:"red"}}>{el.message}</li>)
        }
        </ErrorUl>
        
    </Div>
  )
}

export default ErrorsList