import React from "react";

import Select from "react-select";
let colourOptions:({
  value: string;
  label: string;
  color: string;
  isFixed: boolean;
} | {
  value: string;
  label: string;
  color: string;
  isFixed?: undefined;
})[]
= [
  
];
// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => {
  for(let el of props.leaves){
    let obj = {value: el.id, label: el.email, color: 'black'};
    colourOptions.push(obj);
  }
  return (
  <Select
    isMulti
    name="colors"
    options={colourOptions}
    className="basic-multi-select"
    classNamePrefix="select"
    placeholder="CC To"
    onChange={(data: any) => {
      let arr = [];
      for (let d of data) {
        arr.push(d.value);
      }
      props.onChange(arr);

    }}
  />
);
}