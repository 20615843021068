import styled from "styled-components";

export const AlertBox = styled.div`
  top: 0;
  position: fixed;

  z-index: 100;
  height: 40px;
  background-color: white;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 5px;
`;
