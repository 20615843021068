import React from 'react'
import { Div } from '../../../styled/style'
import logo from '../../../assets/images/logo.png';

function LoginHeader() {
  return (
    <Div style={{margin:'20px 0 20px 100px'}}>
        <img width={70} src={logo} alt=''/>
    </Div>
  )
}

export default LoginHeader