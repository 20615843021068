import React, { useState } from "react";
import {
  LCHeader,
  LCHeaderButton,
  LeaveApplyButton,
  LeaveApplyHeaderDiv,
  LeaveApplySidebar,
} from "./styled";
import { LeaveApply } from "../LeaveApply";
import { Div } from "../../../../styled/style";
import { Navbar } from "../../../../components/Navbar";
import { Header } from "../../../../components/Header";
import { Footer } from "../../../../components/Footer";
import { LeavePending } from "../LeavePending";
import { LeaveHistory } from "../LeaveHistory";
import { Alert } from "../../../../components/Alert";

function LeaveApplyHeader() {
  const [buttonClicked, setButtonClicked] = useState(0);

  return (
    <LeaveApplyHeaderDiv style={{ flexDirection: "column" }}>
      {buttonClicked === 0 ? <Navbar heading="Leave Apply" /> : <Div />}
      {buttonClicked === 1 ? <Navbar heading="Leave Pending" /> : <Div />}
      {buttonClicked === 2 ? <Navbar heading="Leave History" /> : <Div />}
      <Header />
      <Alert />

      <LCHeader>
        <LCHeaderButton
          style={
            buttonClicked === 0
              ? {
                  borderRadius: "8px 0 0 8px",
                  backgroundColor: "grey",
                  color: "white",
                }
              : { borderRadius: "8px 0 0 8px" }
          }
          onClick={() => {
            setButtonClicked(0);
          }}
        >
          Apply
        </LCHeaderButton>
        <LCHeaderButton
          style={
            buttonClicked === 1
              ? {
                  borderRadius: "0px 0 0 0px",
                  backgroundColor: "grey",
                  color: "white",
                }
              : { borderRadius: "0px 0 0 0px" }
          }
          onClick={() => {
            setButtonClicked(1);
          }}
        >
          Pending
        </LCHeaderButton>
        <LCHeaderButton
          style={
            buttonClicked === 2
              ? {
                  borderRadius: "0px 8px 8px 0px",
                  backgroundColor: "grey",
                  color: "white",
                }
              : { borderRadius: "0px 8px 8px 0px" }
          }
          onClick={() => {
            setButtonClicked(2);
          }}
        >
          History
        </LCHeaderButton>
      </LCHeader>
      <LeaveApplySidebar>
        <LeaveApplyButton>Leave</LeaveApplyButton>
        <LeaveApplyButton>Restricted Holiday</LeaveApplyButton>
      </LeaveApplySidebar>
      {buttonClicked === 0 ? <LeaveApply /> : <Div />}
      {buttonClicked === 1 ? <LeavePending /> : <Div />}
      {buttonClicked === 2 ? <LeaveHistory /> : <Div />}

      <Footer />
    </LeaveApplyHeaderDiv>
  );
}

export default LeaveApplyHeader;
