import { useSelector } from "react-redux";
import { Employee, getAllEmployee } from "../../../../utils/userApi";
import { useEffect, useState } from "react";
import {
  Dot,
  EmployeeBodyDiv,
  EmployeeBodyStyle,
  Table,
  Td,
  Th,
  Tr,
} from "./style";
import { BiUserPin } from "react-icons/bi";
import { formateDate } from "../../../../utils/utils";
import { Div } from "../../../../styled/style";

function EmployeeList(props: { employeeList: Employee[] | undefined,handleEmployeeClick:(employeeId: string) => Promise<void>
}) {
  return (
    <EmployeeBodyStyle>
      <EmployeeBodyDiv>
        <Table style={{ width: "100%" }}>
          <Tr>
            <Th>Employee Id</Th>
            <Th>Email</Th>
            <Th>Status</Th>
            <Th>Official Email</Th>
            <Th>Active</Th>
          </Tr>
          {props.employeeList?.map((item) => (
            <Tr onClick={async()=>{
              try{
                await props.handleEmployeeClick(item.id);
              }catch(err){
              }
            }}>
              <Td>{item.employeeId}</Td>
              <Td>{item.email}</Td>
              <Td>{item.status}</Td>
              <Td>{item.officialEmail}</Td>
              <Td>
                <Div style={{justifyContent:'center'}}>
                  {item.signIn?.online ? (
                    <Dot style={{ backgroundColor: "green" }} />
                  ) : (
                    <Dot style={{ backgroundColor: "red" }} />
                  )}
                </Div>
              </Td>
            </Tr>
          ))}
        </Table>
      </EmployeeBodyDiv>
    </EmployeeBodyStyle>
  );
}

export default EmployeeList;
