import React, { useEffect, useState } from "react";
import { Div } from "../../../styled/style";
import {
  AddComponent,
  Heading,
  Select,
  SubHeading,
  TrackerButton,
  TrackerFormBody,
  TrackerInput,
} from "./style";
import { putTracker } from "../../../utils/TrackerApi";
import { useLocation } from "react-router-dom";
import { getTrackerRequestById } from "../../../utils/userApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { setAlert } from "../../../features/signIn/AlertSlice";
import ErrorsList from "../../../components/Errors/ErrorsList";
import axios, { AxiosError } from "axios";

function TrackerForm(props:{handleSubmit?:()=>void}) {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState<any[]>([]);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let trackerId: any = searchParams.get("trackerId");

  const [accountNumberCheck, setAccountNumberCheck] = useState('');
  const auth = useSelector((state: RootState) => state.auth);

  async function callUploadTracker() {
    try {
      if(bankAccountDetails.accountNumber === accountNumberCheck){
        const putData = await putTracker({
          personalDetails: personalDetails,
          bankAccountDetails: bankAccountDetails,
          educationDetails: educationDetails,
          identity: identity,
          professionalDetails: professionalDetails,
          references: references.references,
          id: "",
        });

        if(props.handleSubmit){
          props.handleSubmit();
        }
        dispatch(setAlert({ message: "Tracker Added", visible: true }));
        setFormError([]);
      }else{
        setFormError([{
          message:'account Number doesn\'t match'
        }])
      }
      
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
      }
    }
  }

  interface PersonalDetails {
    I: {
      name: string;
      DOB: string;
    };
    spouse: {
      name: string;
      DOB: string;
    };
    child:
      | {
          name: string;
          DOB: string;
          sex: string;
        }[]
      | [];
    pincode: string;
    country: string;
    currentAddress: string;
    permanentAddress: string;
    phoneNumber: string;
    emailId: string;
  }
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails>({
    I: {
      name: "",
      DOB: "",
    },
    spouse: {
      name: "",
      DOB: "",
    },
    child: [
      {
        name: "",
        DOB: "",
        sex: "",
      },
    ],
    country: "",
    pincode: "",
    currentAddress: "",
    permanentAddress: "",
    phoneNumber: "",
    emailId: "",
  });

  interface ProfessionalDetails {
    company: string[] | [];
  }
  const [professionalDetails, setProfessionalDetails] =
    useState<ProfessionalDetails>({
      company: [""],
    });

  interface EducationDetails {
    tenth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    twelfth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    graduation:
      | {
          stream: string;
          passingPercentage: string;
          passingYear: string;
          college: string;
        }[]
      | [];
  }

  const [educationDetails, setEducationDetails] = useState<EducationDetails>({
    tenth: {
      school: "",
      passingPercentage: "",
      passingYear: "",
    },
    twelfth: {
      school: "",
      passingPercentage: "",
      passingYear: "",
    },
    graduation: [
      {
        college: "",
        passingPercentage: "",
        passingYear: "",
        stream: "",
      },
    ],
  });

  interface References {
    references:
      | {
          name: string;
          phoneNumber: string;
        }[]
      | [];
  }

  const [references, setReferences] = useState<References>({
    references: [
      {
        name: "",
        phoneNumber: "",
      },
    ],
  });

  interface Identity {
    uanNumber: string;
    panNumber: string;
    passport: string;
    aadharNumber: string;
  }

  const [identity, setIdentity] = useState<Identity>({
    uanNumber: "",
    panNumber: "",
    passport: "",
    aadharNumber: "",
  });

  interface BankAccountDetails {
    bankName: string;
    address: string;
    accountNumber: string;
    ifscCode: string;
    accountHolderName: string;
  }

  const [bankAccountDetails, setBankAccountDetails] =
    useState<BankAccountDetails>({
      bankName: "",
      address: "",
      accountNumber: "",
      ifscCode: "",
      accountHolderName: "",
    });

  useEffect(() => {
    if (trackerId != null) {
      const getTracker = async () => {
        const tracker = await getTrackerRequestById(trackerId, auth.jwt);
        setPersonalDetails(tracker.personalDetails);
        setBankAccountDetails(tracker.bankAccountDetails);
        setEducationDetails(tracker.educationDetails);
        setIdentity(tracker.identity);
        setProfessionalDetails(tracker.professionalDetails);
        setReferences({ references: tracker.references });
        console.log(tracker);
      };
      getTracker();
    }
  }, [trackerId]);

  const addChild = () => {
    const newChild = {
      name: "",
      DOB: "",
      sex: "",
    };
    setPersonalDetails({
      ...personalDetails,
      child: [...personalDetails.child, newChild],
    });
  };

  const addCompany = () => {
    const newChild = "";
    setProfessionalDetails({
      ...professionalDetails,
      company: [...professionalDetails.company, newChild],
    });
  };

  const addGraduation = () => {
    const newChild = {
      college: "",
      passingPercentage: "",
      passingYear: "",
      stream: "",
    };

    setEducationDetails({
      ...educationDetails,
      graduation: [...educationDetails.graduation, newChild],
    });
  };

  const addReferences = () => {
    const newChild = {
      name: "",
      phoneNumber: "",
    };

    setReferences({
      ...references,
      references: [...references.references, newChild],
    });
  };
  return (
    <TrackerFormBody>
      <Heading>Personal Details</Heading>
      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Name</SubHeading>
          <TrackerInput
            type="text"
            placeholder="name"
            value={personalDetails.I.name}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                I: { ...personalDetails.I, name: e.target.value },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>DOB</SubHeading>
          <TrackerInput
            type="date"
            placeholder="name"
            value={personalDetails.I.DOB}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                I: { ...personalDetails.I, DOB: e.target.value },
              })
            }
          />
        </Div>
      </Div>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Spouse Name</SubHeading>
          <TrackerInput
            type="text"
            placeholder="name"
            value={personalDetails.spouse.name}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                spouse: { ...personalDetails.spouse, name: e.target.value },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>DOB</SubHeading>
          <TrackerInput
            value={personalDetails.spouse.DOB}
            type="date"
            placeholder="name"
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                spouse: { ...personalDetails.spouse, DOB: e.target.value },
              })
            }
          />
        </Div>
      </Div>

      <Div
        style={{
          flexDirection: "column",
        }}
      >
        {personalDetails.child.length > 0 ? (
          personalDetails.child.map((c, index) => (
            <Div>
              <Div style={{ flexDirection: "column" }}>
                <SubHeading>Child {index + 1} Name</SubHeading>
                <TrackerInput
                  type="text"
                  placeholder="name"
                  value={c.name}
                  onChange={(e) => {
                    const updatedChild = [...personalDetails.child];
                    updatedChild[index].name = e.target.value;
                    setPersonalDetails({
                      ...personalDetails,
                      child: updatedChild,
                    });
                  }}
                />
              </Div>
              <Div style={{ flexDirection: "column" }}>
                <SubHeading>DOB</SubHeading>
                <TrackerInput
                  value={c.DOB}
                  type="date"
                  placeholder="name"
                  onChange={(e) => {
                    const updatedChild = [...personalDetails.child];
                    updatedChild[index].DOB = e.target.value;
                    setPersonalDetails({
                      ...personalDetails,
                      child: updatedChild,
                    });
                  }}
                />
              </Div>

              <Div style={{ flexDirection: "column" }}>
                <SubHeading>Sex</SubHeading>

                <Select
                  value={c.sex}
                  onChange={(e) => {
                    const updatedChild = [...personalDetails.child];
                    updatedChild[index].sex = e.target.value;
                    setPersonalDetails({
                      ...personalDetails,
                      child: updatedChild,
                    });
                  }}
                >
                  <option value="" disabled>
                    Select Sex
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
              </Div>
            </Div>
          ))
        ) : (
          <></>
        )}

        <AddComponent onClick={addChild}>Add</AddComponent>
      </Div>

      <SubHeading>Permanent Address</SubHeading>
      <TrackerInput
        type="text"
        value={personalDetails.permanentAddress}
        placeholder="Permanent Address"
        onChange={(e) =>
          setPersonalDetails({
            ...personalDetails,
            permanentAddress: e.target.value,
          })
        }
      />

      <SubHeading>Phone No</SubHeading>
      <TrackerInput
        type="tel"
        value={personalDetails.phoneNumber}
        placeholder="Phone No"
        onChange={(e) =>
          setPersonalDetails({
            ...personalDetails,
            phoneNumber: e.target.value,
          })
        }
      />

      <SubHeading>Email Id</SubHeading>
      <TrackerInput
        type="email"
        value={personalDetails.emailId}
        placeholder="Email Id"
        onChange={(e) =>
          setPersonalDetails({ ...personalDetails, emailId: e.target.value })
        }
      />

      <SubHeading>pincode</SubHeading>
      <TrackerInput
        type="text"
        value={personalDetails.pincode}
        placeholder="Pincode"
        onChange={(e) =>
          setPersonalDetails({ ...personalDetails, pincode: e.target.value })
        }
      />

      <SubHeading>country</SubHeading>
      <TrackerInput
        type="text"
        value={personalDetails.country}
        placeholder="country"
        onChange={(e) =>
          setPersonalDetails({ ...personalDetails, country: e.target.value })
        }
      />

      <Heading>Professional Details</Heading>
      {professionalDetails.company.map((child, index) => (
        <Div style={{flexDirection:'column'}}>
          <SubHeading>company {index+1}</SubHeading>
          <TrackerInput
            type="text"
            placeholder="company"
            value={child}
            onChange={(e) => {
              let updatedCompany = [...professionalDetails.company];
              updatedCompany[index] = e.target.value;
              setProfessionalDetails({ company: updatedCompany });
            }}
          />
        </Div>
      ))}
      <AddComponent onClick={addCompany}>Add</AddComponent>

      <Heading>Educational Detals</Heading>
      <SubHeading>Intermediate School</SubHeading>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>School</SubHeading>
          <TrackerInput
            type="text"
            placeholder="School"
            value={educationDetails.tenth.school}
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                tenth: { ...educationDetails.tenth, school: e.target.value },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Passing Percentage</SubHeading>
          <TrackerInput
            type="text"
            placeholder="Passing percentage"
            value={educationDetails.tenth.passingPercentage}
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                tenth: {
                  ...educationDetails.tenth,
                  passingPercentage: e.target.value,
                },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Passing Year</SubHeading>
          <TrackerInput
            type="text"
            placeholder="Passing Year"
            value={educationDetails.tenth.passingYear}
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                tenth: {
                  ...educationDetails.tenth,
                  passingYear: e.target.value,
                },
              })
            }
          />
        </Div>

      </Div>

      <SubHeading>High School</SubHeading>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>School</SubHeading>
          <TrackerInput
            type="text"
            value={educationDetails.twelfth.school}
            placeholder="School"
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                twelfth: {
                  ...educationDetails.twelfth,
                  school: e.target.value,
                },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Passing Percentage</SubHeading>
          <TrackerInput
            type="text"
            placeholder="Passing percentage"
            value={educationDetails.twelfth.passingPercentage}
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                twelfth: {
                  ...educationDetails.twelfth,
                  passingPercentage: e.target.value,
                },
              })
            }
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Passing Year</SubHeading>
          <TrackerInput
            type="text"
            placeholder="Passing Year"
            value={educationDetails.twelfth.passingYear}
            onChange={(e) =>
              setEducationDetails({
                ...educationDetails,
                twelfth: {
                  ...educationDetails.twelfth,
                  passingYear: e.target.value,
                },
              })
            }
          />
        </Div>
      </Div>

      <SubHeading>Degree's and Course's</SubHeading>

      {educationDetails.graduation.map((college, index) => (
        <Div style={{ flexDirection: "column" }}>
        <Div>
          <Div style={{ flexDirection: "column" }}>
            <SubHeading>Degree or Course {index+1} </SubHeading>
            <TrackerInput
              type="text"
              placeholder="Degree or College"
              value={college.college}
              onChange={(e) => {
                let updatedList = [...educationDetails.graduation];
                updatedList[index].college = e.target.value;
                setEducationDetails({
                  ...educationDetails,
                  graduation: updatedList,
                });
              }}
            />
          </Div>

          <Div style={{ flexDirection: "column" }}>
          <SubHeading>Stream</SubHeading>
          <TrackerInput
            type="text"
            placeholder="Stream"
            value={college.stream}
            onChange={(e) => {
              let updatedList = [...educationDetails.graduation];
              updatedList[index].stream = e.target.value;
              setEducationDetails({
                ...educationDetails,
                graduation: updatedList,
              });
            }}
          />
        </Div>
          <Div style={{ flexDirection: "column" }}>
            <SubHeading>Passing Percentage</SubHeading>
            <TrackerInput
              type="text"
              value={college.passingPercentage}
              placeholder="Passing percentage"
              onChange={(e) => {
                let updatedList = [...educationDetails.graduation];
                updatedList[index].passingPercentage = e.target.value;
                setEducationDetails({
                  ...educationDetails,
                  graduation: updatedList,
                });
              }}
            />
          </Div>
          <Div style={{ flexDirection: "column" }}>
            <SubHeading>Passing Year</SubHeading>
            <TrackerInput
              type="text"
              placeholder="Passing Year"
              value={college.passingYear}
              onChange={(e) => {
                let updatedList = [...educationDetails.graduation];
                updatedList[index].passingYear = e.target.value;
                setEducationDetails({
                  ...educationDetails,
                  graduation: updatedList,
                });
              }}
            />
          </Div>
        </Div>
        </Div>

      ))}

      <AddComponent onClick={addGraduation}>Add</AddComponent>

      <Heading>References</Heading>

      {references.references.map((reference, index) => (
        <Div  style={{ flexDirection: "column" }}>
          <SubHeading>Reference {index + 1}</SubHeading>

          <Div>
            <Div style={{ flexDirection: "column" }}>
              <SubHeading>Name</SubHeading>
              <TrackerInput
                type="text"
                placeholder="name"
                value={reference.name}
                onChange={(e) => {
                  let updatedList = [...references.references];
                  updatedList[index].name = e.target.value;
                  setReferences({
                    ...references,
                    references: updatedList,
                  });
                }}
              />
            </Div>
            <Div style={{ flexDirection: "column" }}>
              <SubHeading>Contact Number</SubHeading>
              <TrackerInput
                type="text"
                placeholder="name"
                value={reference.phoneNumber}
                onChange={(e) => {
                  let updatedList = [...references.references];
                  updatedList[index].phoneNumber = e.target.value;
                  setReferences({
                    ...references,
                    references: updatedList,
                  });
                }}
              />
            </Div>
          </Div>
        </Div>
      ))}

      <AddComponent onClick={addReferences}>Add</AddComponent>

      <Heading>Identity</Heading>

      <Div style={{ flexDirection: "column" }}>
        <SubHeading>UAN</SubHeading>
        <TrackerInput
          type="text"
          value={identity.uanNumber}
          placeholder="UAN"
          onChange={(e) => {
            setIdentity({ ...identity, uanNumber: e.target.value });
          }}
        />
      </Div>

      <Div style={{ flexDirection: "column" }}>
        <SubHeading>PAN No</SubHeading>
        <TrackerInput
          type="text"
          value={identity.panNumber}
          placeholder="PAN No"
          onChange={(e) => {
            setIdentity({ ...identity, panNumber: e.target.value });
          }}
        />
      </Div>

      <Div style={{ flexDirection: "column" }}>
        <SubHeading>Passport No</SubHeading>
        <TrackerInput
          value={identity.passport}
          type="text"
          placeholder="Passport No"
          onChange={(e) => {
            setIdentity({ ...identity, passport: e.target.value });
          }}
        />
      </Div>

      <Div style={{ flexDirection: "column" }}>
        <SubHeading>Aadhar no</SubHeading>
        <TrackerInput
          value={identity.aadharNumber}
          type="text"
          placeholder="Aadhar no"
          onChange={(e) => {
            setIdentity({ ...identity, aadharNumber: e.target.value });
          }}
        />
      </Div>

      <Heading>Bank Account Details</Heading>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Bank name</SubHeading>
          <TrackerInput
            type="text"
            placeholder="name"
            value={bankAccountDetails.bankName}
            onChange={(e) => {
              setBankAccountDetails({
                ...bankAccountDetails,
                bankName: e.target.value,
              });
            }}
          />
        </Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Address</SubHeading>
          <TrackerInput
            type="text"
            value={bankAccountDetails.address}
            placeholder="name"
            onChange={(e) => {
              setBankAccountDetails({
                ...bankAccountDetails,
                address: e.target.value,
              });
            }}
          />
        </Div>
      </Div>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Account no</SubHeading>
          <TrackerInput
            type="password"
            placeholder="Account no"
            value={bankAccountDetails.accountNumber}
            onChange={(e) => {
              setBankAccountDetails({
                ...bankAccountDetails,
                accountNumber: e.target.value,
              });
            }}
          />
        </Div>

        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Re-enter Account no</SubHeading>
          <TrackerInput
            type="password"
            placeholder="Account no"
            value={accountNumberCheck}
            onChange={(e) => setAccountNumberCheck(e.target.value)}
          />
        </Div>

        <Div style={{ flexDirection: "column" }}>
          <SubHeading>IFSC Code</SubHeading>
          <TrackerInput
            type="text"
            value={bankAccountDetails.ifscCode}
            placeholder="IFSC Code"
            onChange={(e) => {
              setBankAccountDetails({
                ...bankAccountDetails,
                ifscCode: e.target.value,
              });
            }}
          />
        </Div>
      </Div>

      <Div>
        <Div style={{ flexDirection: "column" }}>
          <SubHeading>Account holder Name</SubHeading>
          <TrackerInput
            type="text"
            value={bankAccountDetails.accountHolderName}
            placeholder="Account holder Name"
            onChange={(e) => {
              setBankAccountDetails({
                ...bankAccountDetails,
                accountHolderName: e.target.value,
              });
            }}
          />
        </Div>
      </Div>

      <ErrorsList error={formError} />


      <Div>
        <TrackerButton onClick={callUploadTracker}>Submit</TrackerButton>
      </Div>
    </TrackerFormBody>
  );
}

export default TrackerForm;
