import React, { useState } from "react";
import { Navbar } from "../../../components/Navbar";
import { Header } from "../../../components/Header";
import { Footer } from "../../../components/Footer";
import { Div } from "../../../styled/style";
import { LeaveBallanceCard } from "../components/LeaveBallanceCard";
import { LeaveBallanceBody } from "./style";
import LeaveTransactionHistory from "../components/LeaveTransactionHistory";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  getAllCasualLeaves,
  getAllEarnedLeaves,
  getAllSickLeave,
  getUserData,
} from "../../../utils/userApi";
import { Spinner } from "../../../components/Spinner";

const LeaveBallance = () => {
  const [leaveSelected, setLeaveSelected] = useState(0);
  const queryClient = useQueryClient();
  const auth = useSelector((state: RootState) => state.auth);

  const { data: user, isLoading: userLoading} = useQuery({
    queryFn: () => {
      return getUserData(auth.jwt);
    },
    queryKey: ["user"],
  });



  const { data: leaveList, isLoading } = useQuery({
    queryFn: () => {
      if (leaveSelected == 0) {
        return getAllSickLeave(auth.jwt);
      } else if (leaveSelected == 1) {
        return getAllEarnedLeaves(auth.jwt);
      } else {
        return getAllCasualLeaves(auth.jwt);
      }
    },
    queryKey: ["leaveList", { leaveSelected }],
  });
  
if(userLoading){
  return <Spinner/>
}
  return (
    <Div>
      <Navbar heading="Leave Ballance" />
      <Header />
      <div style={{ display: "flex" }}>
        <LeaveBallanceBody>
          <Div>
            <LeaveBallanceCard
              onClick={() => {
                setLeaveSelected(0);
              }}
              heading="Sick Leave"
              balance={user? user.leave.sickLeave+'':'-'}
              selected={leaveSelected === 0}
            />
            <LeaveBallanceCard
              onClick={() => setLeaveSelected(1)}
              heading="Earned Leave"
              balance={user? user.leave.earnedLeave+'':'-'}
              selected={leaveSelected === 1}
            />
            <LeaveBallanceCard
              onClick={() => {
                setLeaveSelected(2);
              }}
              heading="Casual Leave"
              balance={user? user.leave.casualLeave+'':'-'}
              selected={leaveSelected === 2}
            />
          </Div>
          <LeaveTransactionHistory leaveList={leaveList}/>
        </LeaveBallanceBody>
      </div>
      <Footer />
    </Div>
  );
};

export default LeaveBallance;
