import axios from "axios";
import { connection } from "./utils";

interface AssignEmailResponse {
  user: {
    role: string;
    trackerId: string;
    email: string;
    password: string;
    status: number;
    officialEmail: string;
    createdAt: string;
    updatedAt: string;
    id: string;
    employeeId: string;
  };
}

export interface Employee {
  role: string;
  trackerId:
    | string
    | {
        id: string;
        personalDetails: {
          I: {
            name: string;
            DOB: string;
          };
          spouse: {
            name: string;
            DOB: string;
          };
          child:
            | {
                name: string;
                DOB: string;
                sex: string;
              }[]
            | [];
          pincode: string;
          country: string;
          currentAddress: string;
          permanentAddress: string;
          phoneNumber: string;
          emailId: string;
        };
        professionalDetails: {
          company: string[] | [];
        };
        educationDetails: {
          tenth: {
            school: string;
            passingPercentage: string;
            passingYear: string;
          };
          twelfth: {
            school: string;
            passingPercentage: string;
            passingYear: string;
          };
          graduation:
            | {
                stream: string;
                passingPercentage: string;
                passingYear: string;
                college: string;
              }[]
            | [];
        };
        references:
          | {
              name: string;
              phoneNumber: string;
            }[]
          | [];

        identity: {
          uanNumber: string;
          panNumber: string;
          passport: string;
          aadharNumber: string;
        };
        bankAccountDetails: {
          bankName: string;
          address: string;
          accountNumber: string;
          ifscCode: string;
          accountHolderName: string;
        };
        createdAt: string;
        updatedAt: string;
      };
  email: string;
  password: string;
  status: number;
  officialEmail: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  employeeId: string;
  signIn?: {
    online: Boolean;
    currentSwipe: string;
  };
  joiningDate: string;
  leave: {
    earnedLeave: number;
    sickLeave: number;
    casualLeave: number;
  };
  LTABallance: string;

  AllowanceBallance: {
    month: string;
    year: string;
    amount: number;
  }[];

  payslips:
    | [
        {
          month: string;
          year: string;
          payslipUrl: string[];
          id: string;
        }
      ]
    | [];
  Band:
    | string
    | {
        id: string;
        Bands: string;
        LTA: string;
        EntertainmentReimbursement: string;
        createdAt: string;
        updatedAt: string;
      };
}

export interface getSwipesResponse {
  swipes: [
    {
      u_id: string;
      in: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
      out: string;
      id: string;
    }
  ];
  time: number;
}

export interface LeaveList {
  id: string;
  leaveType: string;
  toDate: string;
  fromDate: string;
  applyTo: string;
  ccTo: [string];
  contactDetails: string;
  reason: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
  status: number;
}

export interface AllowanceResponse {
  id: string;
  allowance: string;
  userId: string;
  date: Date;
  documents: [string];
  status: number;
  createdAt: string;
  updatedAt: string;
  allowanceType: string;
  payment: number;
  remark: string;
  userRemark: string;
}



export interface tracker {
  id: string;
  personalDetails: {
    I: {
      name: string;
      DOB: string;
    };
    spouse: {
      name: string;
      DOB: string;
    };
    child:
      | {
          name: string;
          DOB: string;
          sex: string;
        }[]
      | [];
    pincode: string;
    country: string;
    currentAddress: string;
    permanentAddress: string;
    phoneNumber: string;
    emailId: string;
  };
  professionalDetails: {
    company: string[] | [];
  };
  educationDetails: {
    tenth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    twelfth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    graduation:
      | {
          stream: string;
          passingPercentage: string;
          passingYear: string;
          college: string;
        }[]
      | [];
  };
  references:
    | {
        name: string;
        phoneNumber: string;
      }[]
    | [];
  identity: {
    uanNumber: string;
    panNumber: string;
    passport: string;
    aadharNumber: string;
  };
  bankAccountDetails: {
    bankName: string;
    address: string;
    accountNumber: string;
    ifscCode: string;
    accountHolderName: string;
  };
  createdAt: string;
  updatedAt: string;
}

export const signIn = async (data: {
  email: string;
  password: string;
}): Promise<{ token: string; role: string }> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.signIn}`,
    data
  );
  return response.data;
};

export const assignEmail = async (
  data: {
    trackerId: string;
    employeeId: string;
    email: string;
    bandId: string;
  },
  jwt: string
): Promise<AssignEmailResponse> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.superAdmin.assignEmployeeEmail}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );
  return response.data;
};

export const getAllEmployee = async (jwt: string): Promise<Employee[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.superAdmin.getAllEmployee}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const swipe = async (jwt: string): Promise<Employee> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.user.swipe}`,
    {},
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getSwipes = async (
  date: string,
  jwt: string
): Promise<getSwipesResponse> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getSwipes}${date}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getGaps = async (
  date: string,
  jwt: string
): Promise<{
  present: [string];
  absent: [string];
  leave: [string];
  leaveSpec: {
    "earned-leave": any[];
    "sick-leave": any[];
    "casual-leave": any[];
  };
}> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getGaps}${date}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getEmployeeEmailList = async (
  jwt: string
): Promise<{ present: [string]; absent: [string] }> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getEmployeeList}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getUserData = async (jwt: string): Promise<Employee> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getUserData}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getAllSickLeave = async (jwt: string): Promise<LeaveList[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.allSickLeaves}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getAllCasualLeaves = async (jwt: string): Promise<LeaveList[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.allCasualLeaves}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getAllEarnedLeaves = async (jwt: string): Promise<LeaveList[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.allEarnedLeaves}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getAllowanceRequest = async (
  jwt: string
): Promise<AllowanceResponse[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getAllowanceRequest}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const raiseAllowancesRequest = async (
  data: {
    raisedAmount: string;
    date: string;
    documents: string[];
    allowanceType: string;
    userRemark:string;
  },
  jwt: string
): Promise<AssignEmailResponse> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.user.raiseAllowancesRequest}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );
  return response.data;
};

export const getSignedUrl = async (data:{
  fileExtension:string,
  contentType: string
}): Promise<string> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.user.getUploadSignedUrl}`,
    data

  );
  return response.data.url;
};

export const uploadFiles = async (
  data: FormData
): Promise<string[]> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.user.upload}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data.files;
};

export const getTrackerRequestById = async (
  trackerId: string,
  jwt: string
): Promise<tracker> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getTracker}/${trackerId}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const updateEmployee = async (
  data: Employee,
  jwt: string
): Promise<Employee> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.superAdmin.updateEmployee}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const callSendUpdatePasswordRequest = async (data: {
  officialEmail: string;
}): Promise<{}> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.user.sendUpdatePasswordRequest}`,
    data,
    {}
  );
  return response.data;
};

export const callUpdatePassword = async (
  data: {
    password: string;
  },
  jwt: string
): Promise<{}> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.user.updatePassword}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getUploadFile = async (key:string): Promise<string> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getUploadedFiles}?prefix=${key}`
  );
  return response.data.url;
}