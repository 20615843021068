import { keyframes, styled } from "styled-components";

export const LeaveApplyForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 138px 10px 10px 375px;
  width: 150vh;
  height: 130vh;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 50px;
  background-color: grey;
`;

export const LeaveApplyButton = styled.button`
  background-color: #f31559;
  border:none;
  padding:25px;
  margin : 10px;
  border-radius: 10px;
  color:white;
  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
`;

