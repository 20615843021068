import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { setAlert } from "../../features/signIn/AlertSlice";
import { AlertBox } from "./Style";
import { Div } from "../../styled/style";
import {IoCheckmarkDoneCircleSharp, IoCheckmarkDoneSharp} from 'react-icons/io5';
import { AiOutlineCloseCircle } from "react-icons/ai";
const Alert = () => {
  const [visible, setVisible] = useState(false);

  const alertState = useSelector((state: RootState) => state.alert);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (alertState.visible) {
      setVisible(true);

      const timer = setTimeout(() => {
        setVisible(false);
        dispatch(setAlert({ message: "", visible: false }));
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [alertState.visible]);

  return (
    <Div style={{justifyContent: 'center', alignItems:'center'}}>
      <AlertBox style={visible ? {} : { display: "none" }}>
        <p>{alertState.message}</p>
        {alertState.success === true?<IoCheckmarkDoneSharp size={30} color="#557A46" />:<AiOutlineCloseCircle size={30} color="red"/>}
        
      </AlertBox>
    </Div>
  );
};

export default Alert;
