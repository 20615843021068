import React from "react";
import { LoginCarouselBody } from "./style";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function LoginCarousel() {
  return (
    <LoginCarouselBody>
      <Carousel>
        <div>
          <img src="https://evrenglobal.greythr.com/uas/v1/cms/asset/17042120-a978-4094-b8c9-deeed84dfe7e"  alt=""/>
          <p className="legend">Legend 1</p>
        </div>
        <div>
          <img src="https://evrenglobal.greythr.com/uas/v1/cms/asset/17042120-a978-4094-b8c9-deeed84dfe7e" alt="" />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img src="https://evrenglobal.greythr.com/uas/v1/cms/asset/5fe7bab4-8479-4266-a749-97a7208b7a40" alt="" />
          <p className="legend">Legend 3</p>
        </div>
      </Carousel>
    </LoginCarouselBody>
  );
}

export default LoginCarousel;
