import { styled } from "styled-components";

export const CreatePostParent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  margin-bottom: 400px;
`;

export const CreatePostHeader = styled.div`
  display: flex;
  height: 80px;
  width: 100%;
  position: fixed;
  padding-left: 380px;
  margin-top: 50px;
  background-color: #eeeded;  
  padding-top: 20px;
`;

export const CreatePostSidebar = styled.div`
  display: flex;
  height: 100%;
  position: fixed;
  background-color: #eeeded;
  margin-left: 120px;
  border: 2px solid white;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  padding: 80px 40px 100px 120px;
`;

export const SidebarButton = styled.div`
  display: flex;
  &:hover {
    font-weight: bold;
  }
`;

export const CreatePostHeading = styled.div`
  display: flex;
`;

export const Vr = styled.div`
  display: flex;
  border: 1px solid #f31559;
`;

export const CreatePostButton = styled.button`
  border: none;
  outline: none;
  height: 30px;
  color: white;
  background-color: #f31559;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
`;

export const PostCard = styled.div`
  display: flex;
  width: 600px;
  height: 300px;
  padding: 15px;
  margin-top: 50px;
  flex-direction: column;
  background-color: #eeeded;  
`;

export const PostCardHeader = styled.div`
  display: flex;
  width: 100%;
  height: 58px;
`;

export const PostCardBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
`;
