import React, { useState } from "react";
import { Button, FormContainer, InputField, InputLabel, ToggleInput, ToggleLabel } from "./style";
import { BandInterface } from "../../../utils/BandsApi";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

const BandForm = (props: {addBandMutateAsync: UseMutateAsyncFunction<BandInterface, unknown, BandInterface, unknown>}) => {
  const [formData, setFormData] = useState<BandInterface>({
    Bands:"",
    createdAt:"",
    EntertainmentReimbursement:"",
    id:"",
    LTA:"",
    updatedAt:""
  });

  // Handle form field changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    console.log(formData);
    
    e.preventDefault();

    try{
      await props.addBandMutateAsync(formData);
      setFormData((prev)=>({ 
        ...prev,
        Bands:"",
        EntertainmentReimbursement: "",
        LTA:""
      }));

    }catch(err){
      console.log(err);
      
    }
    // TODO: Handle form submission, e.g., send data to the server
  };
  return (
    <FormContainer>
      <h2>Add Band</h2>
      <form onSubmit={handleSubmit}>
        <InputLabel>Band Name</InputLabel>
        <InputField
          type="text"
          name="Bands"
          value={formData.Bands}
          onChange={handleInputChange}
        />

        <InputLabel>Entertainment Reimbursement</InputLabel>
        <InputField
          type="text"
          name="EntertainmentReimbursement"
          value={formData.EntertainmentReimbursement}
          onChange={handleInputChange}
        />

        <InputLabel>LTA</InputLabel>
        <InputField
          type="text"
          name="LTA"
          value={formData.LTA}
          onChange={handleInputChange}
        />

        <Button type="submit">Add Band</Button>
      </form>
    </FormContainer>
  );
};

export default BandForm;
