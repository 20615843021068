import React, { useEffect, useState } from "react";
import logo from "../../../assets/images/logo.png";
import { Div } from "../../../styled/style";
import {
  LoginButton,
  LoginForgetPassword,
  LoginFormBody,
  LoginInput,
  LoginMainForm,
} from "./style";
import { callSendUpdatePasswordRequest, signIn } from "../../../utils/userApi";
import { useSelector, useDispatch } from "react-redux";
import { login, signOut } from "../../../features/signIn/authSlice";
import { RootState } from "../../../app/store";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../config/route.config";
import axios, { AxiosError } from "axios";
import ErrorsList from "../../../components/Errors/ErrorsList";
import { IoIosArrowRoundBack } from "react-icons/io";
import { UpdatePasswordComponent } from "../../../components/UpdatePassword";
import { useMutation } from "@tanstack/react-query";

function EmailToChangePassword(props: { backToLogin: () => void }) {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [timer, setTimer] = useState(30); 
  const [timerActive, setTimerActive] = useState(false);
  const [formError, setFormError] = useState<any[]>([]);

  useEffect(() => {
    let countdown:any;

    if (timerActive && timer > 0) {
      countdown = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // 1000ms = 1 second
    } else if (timer === 0) {
      // Timer has reached 0, update the state or perform any action you need.
      setEmailSent(false); // Change the state after 30 seconds
    }

    // Cleanup the timer when the component unmounts or timerActive becomes false.
    return () => clearTimeout(countdown);
  }, [timer, timerActive]);

  const { mutateAsync: allowanceRequestMutation } = useMutation({
    mutationFn: async () =>
      await callSendUpdatePasswordRequest(
        {officialEmail:email}
      ),
    onSuccess: () => {},
    onError: (err) => {
      
    },
  });
  const navigate = useNavigate();

  const onEmployee = () => {
    navigate(RoutePath.Home);
  };

  async function callLogin() {
    try {
      await allowanceRequestMutation();
      if(email===''){
        setFormError([
          {
            message:'email Id Empty'
          }
        ])
        return;
      }
      setTimer(30);
      setTimerActive(true);
      setEmailSent(true);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
        console.log(formError);
      }
      console.log(err);
    }
  }
  return (
    <LoginFormBody>
        <Div style={{ flexDirection: "column" }}>
          <Div onClick={props.backToLogin}>
            <IoIosArrowRoundBack
              size={40}
              style={{ color: "f31559", cursor: "pointer" }}
            />
          </Div>
          <Div style={{ justifyContent: "center", alignContent: "center" }}>
            <img src={logo} alt="" width={50} />
          </Div>
          <Div style={{ justifyContent: "center", alignContent: "center" }}>
            <p style={{ fontWeight: "bold" }}>Request Password Change 👋</p>
          </Div>
          <LoginMainForm>
            <p>Login ID</p>
            <LoginInput
              type="text"
              placeholder="employee id"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required
            />

            <ErrorsList error={formError} />


            <Div style={{ height: "40px" }}>
              {
                !emailSent?<LoginButton onClick={callLogin}>Request</LoginButton>:<p>Password change Email sent, resend after <p style={{color:"#f31559"}}>{timer} seconds</p></p>
              }
            </Div>
          </LoginMainForm>
        </Div>
    </LoginFormBody>
  );
}

export default EmailToChangePassword;
