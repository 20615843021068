import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AuthState {
  role: string,
  jwt: string
}

const initialState: AuthState = {
  role: 'user',
  jwt: ''
}

export const authSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    login: (state, action : PayloadAction<AuthState>) => {
      state.role = action.payload.role;
      state.jwt = action.payload.jwt;

    },
    signOut: (state) => {
      state.role ='user';
      state.jwt = ''
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, signOut} = authSlice.actions

export default authSlice.reducer