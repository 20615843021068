import React from "react";
import { LeavePendingBody, Table, Td, Th, Tr } from "./style";
import { SlCalender } from "react-icons/sl";
import { Div } from "../../../../styled/style";
import { useQuery } from "@tanstack/react-query";
import { getPendingLeave } from "../../../../utils/LeaveApi";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { formateDate } from "../../../../utils/utils";
import { Spinner } from "../../../../components/Spinner";

function LeavePending() {
  const auth = useSelector((state: RootState) => state.auth);
  const { data: leaves, isLoading } = useQuery({
    queryFn: () => getPendingLeave(auth.jwt),
    queryKey: ["leave"],
  });

  if (isLoading) {
    return <Spinner/>;
  }
  return leaves !== undefined && leaves.length > 0 ? (
    <LeavePendingBody style={{width:'100%'}}>
      <Table style={{width:'100%'}}>
        <Tr>
          <Th>contact details</Th>
          <Th>applied on</Th>
          <Th>from</Th>
          <Th>to date</Th>
          <Th>status</Th>
        </Tr>
        {leaves.map((el) => (
          <Tr>
            <Td>{el.contactDetails}</Td>
            <Td>{formateDate(new Date(el.createdAt))}</Td>
            <Td>{formateDate(new Date(el.fromDate))}</Td>
            <Td>{formateDate(new Date(el.toDate))}</Td>
            <Td>{el.status === 0?<p>pending</p>:el.status === 1?<p>accepted</p>:<p>rejected</p>}</Td>
          </Tr>
        ))}
      </Table>
    </LeavePendingBody>
  ) : (
    <LeavePendingBody>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <SlCalender size={300} />
      </Div>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <p>There are no pending records of any leave transaction</p>
      </Div>
    </LeavePendingBody>
  );
}

export default LeavePending;
