import styled from "styled-components";

export const Head = styled.div`
  padding: 10px 40px;
  width: 200px;
  height: 100vh;
  box-shadow: 10px 0px 10px 10px rgba(0,0,0,0.5);
  background: #fff;
  @media (max-width: 400px) {
    background: yellow;
    padding: 0;
  }
  position: fixed;
  z-index: 10;
  overflow-y: auto;
`;

export const Anchor = styled.div`
  display: flex;
  &:hover {
    font-weight: bold;
  };
  
`;

export const Profile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        font-weight: bold;
      }
`;
