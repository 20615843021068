import styled from 'styled-components';

// Define styled components for your form elements
export const FormWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #f31559;
  border-radius: 5px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Button = styled.button`
  background-color: #f31559;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;