import { styled } from "styled-components";

export const NavbarStyle = styled.div`
  top: 0;
  display: flex;
  height: 50px;
  position: fixed;
  padding-left: 250px;
  background-color: #F31559;
  width: 100%;
  z-index: 5;
`;

export const Header = styled.div`
  color:white;
  display: flex;
  flex-direction: row;
  &:hover {
    font-weight: bold;
  };
  cursor: pointer;
  user-select:none;
`;

// marginLeft:"auto", order:1, display:"flex",flexDirection:"row" , alignItems:"center"
export const HeaderEnd = styled.div`
    margin-left: auto;
    order:1;
    display:flex;
    flex-direction : row;
    align-items : center;
    &:hover {
        font-weight: bold;
      }
`


