import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { Anchor, Head, Profile } from "./style";
import { FaUserAlt } from "react-icons/fa";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";

import { AiOutlineLogout } from "react-icons/ai";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../config/route.config";
import { Div } from "../../styled/style";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../features/signIn/authSlice";
import { RootState } from "../../app/store";

function Header() {
  const [leaveDropdown, setLeaveDropdown] = useState(false);
  const [userName, setuserName] = useState("");
  const auth = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();

  useEffect(() => {
    let name = user.officialEmail.split("@");
    setuserName(name[0]);
  }, [user]);

  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("jwt");
    dispatch(signOut());
  };
  const onFeed = () => {
    navigate(RoutePath.Feeds);
  };

  const onHome = () => {
    navigate(RoutePath.Home);
  };

  const onLeaveApply = () => {
    navigate(RoutePath.LeaveApply);
  };

  const onAllowance = () => {
    navigate(RoutePath.UserAllowance);
  };

  const onHolidayCalender = () => {
    navigate(RoutePath.HolidayCalender);
  };

  const onLeaveBallance = () => {
    navigate(RoutePath.LeaveBallance);
  };

  const onAttendance = () => {
    navigate(RoutePath.Attendance);
  };

  const onTrackerRequest = () => {
    navigate(RoutePath.TrackerRequest);
  };

  const onEmployee = () => {
    navigate(RoutePath.Employee);
  };

  const onLeaveApplication = () => {
    navigate(RoutePath.LeaveApplications);
  };

  const onAllowanceRequests = () => {
    navigate(RoutePath.AllowanceRequests);
  };

  const onEmployeeAttendance = () => {
    navigate(RoutePath.EmployeeAttendance);
  };

  const onUserRegularization = () => {
    navigate(RoutePath.UserRegularization);
  };

  const onAdminDashboard = () => {
    navigate(RoutePath.AdminDashboard);
  };
  return (
    <Head>
      <Profile>
        <img src={logo} alt="logo" width={40} />
        <p style={{ marginLeft: 10, cursor: "pointer", userSelect: "none" }}>
          My Vizlogic
        </p>
      </Profile>

      <Profile>
        <p style={{ cursor: "pointer", userSelect: "none" }}>
          Hi {auth.role === "SUPER_ADMIN" ? "Admin" : userName}
        </p>
        <FaUserAlt style={{ marginLeft: 10 }} />
      </Profile>

      {auth.role !== "SUPER_ADMIN" ? (
        <Anchor onClick={onHome}>
          <p style={{ cursor: "pointer", userSelect: "none" }}>Home</p>
        </Anchor>
      ) : (
        <Anchor onClick={onAdminDashboard}>
          <p style={{ cursor: "pointer", userSelect: "none" }}>
            Admin Dashboard
          </p>
        </Anchor>
      )}

      <Anchor onClick={onFeed}>
        <p style={{ cursor: "pointer", userSelect: "none" }}>Feeds</p>
      </Anchor>

      <Anchor>
        <p style={{ cursor: "pointer", userSelect: "none" }}>To do</p>
      </Anchor>

      <Anchor>
        <p style={{ cursor: "pointer", userSelect: "none" }}>Salary</p>
      </Anchor>

      {auth.role !== "SUPER_ADMIN" ? (
        <Div>
          <Anchor onClick={onLeaveApply} style={{ paddingRight: "50px" }}>
            <p style={{ cursor: "pointer", userSelect: "none" }}>Leave</p>
          </Anchor>
          <Div onClick={() => setLeaveDropdown(!leaveDropdown)}>
            {leaveDropdown ? (
              <IoIosArrowDropup style={{ marginTop: "15px" }} size={21} />
            ) : (
              <IoIosArrowDropdown style={{ marginTop: "15px" }} size={21} />
            )}
          </Div>
        </Div>
      ) : (
        <></>
      )}

      {leaveDropdown ? (
        <Div style={{ flexDirection: "column", paddingLeft: "10px" }}>
          <Anchor onClick={onLeaveApply}>
            <p style={{ cursor: "pointer", userSelect: "none" }}>Leave Apply</p>
          </Anchor>
          <Anchor onClick={onHolidayCalender}>
            <p style={{ cursor: "pointer", userSelect: "none" }}>
              Holiday Calender
            </p>
          </Anchor>
          <Anchor onClick={onLeaveBallance}>
            <p style={{ cursor: "pointer", userSelect: "none" }}>
              Leave Ballance
            </p>
          </Anchor>
        </Div>
      ) : (
        <></>
      )}

      {auth.role === "SUPER_ADMIN" ? (
        <Div>
          <Anchor>
            <p
              onClick={onAllowanceRequests}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              Allowance Requests
            </p>
          </Anchor>
        </Div>
      ) : (
        <Div>
          <Anchor>
            <p
              onClick={onAllowance}
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              Allowances
            </p>
          </Anchor>
        </Div>
      )}

      {auth.role !== "SUPER_ADMIN" ? (
        <Anchor>
          <p
            onClick={onAttendance}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            Attendance
          </p>
        </Anchor>
      ) : (
        <Anchor>
          <p
            onClick={onEmployeeAttendance}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            Attendance
          </p>
        </Anchor>
      )}

      {auth.role === "SUPER_ADMIN" ? (
        <Anchor>
          <p
            onClick={onUserRegularization}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            User Regularization's
          </p>
        </Anchor>
      ) : (
        <></>
      )}

      {auth.role === "SUPER_ADMIN" ? (
        <Anchor>
          <p
            onClick={onTrackerRequest}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            Tracker Request
          </p>
        </Anchor>
      ) : (
        <></>
      )}

      {auth.role === "SUPER_ADMIN" ? (
        <Anchor>
          <p
            onClick={onEmployee}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            Employee's
          </p>
        </Anchor>
      ) : (
        <></>
      )}

      {auth.role === "SUPER_ADMIN" ? (
        <Anchor>
          <p
            onClick={onLeaveApplication}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            Leave Application's
          </p>
        </Anchor>
      ) : (
        <></>
      )}

      <Profile>
        <p style={{ cursor: "pointer", userSelect: "none" }} onClick={logout}>
          Log Out
        </p>
        <AiOutlineLogout style={{ marginLeft: 10 }} />
      </Profile>
    </Head>
  );
}

export default Header;
