import { styled } from "styled-components";

export const Foot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 50px;
  margin-left: 240px;
  position: fixed;
  bottom: 0;
`;

export const FooterAnchor = styled.div`
  display: flex;
  &:hover {
    font-weight: bold;
  }
  margin: 8px;
  cursor: pointer;
  user-select:none;
`;
