import { GoVerified } from "react-icons/go";
import { Div } from "../../../styled/style";
import { AllowanceRequestsResponse } from "../../../utils/AllowanceApi";
import { AllowanceResponse, getUploadFile } from "../../../utils/userApi";
import { formateDate } from "../../../utils/utils";
import { CommentInput, Table, Td, Th, Tr } from "../../UserAllowance/components/style";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { Dot } from "../../Employee/components/style";
import { useState } from "react";

const AllowanceRequestList = (props: {
  leaveList: AllowanceRequestsResponse[] | undefined;
  acceptAllowanceRequest: (
    allowanceRequestId: string,
    amount: string,
    remark: string
  ) => Promise<void>;
  rejectAllowanceRequest: (
    allowanceRequestId: string,
    amount: string,
    remark: string
  ) => Promise<void>;
  updateAllowancePayment: (allowanceRequestId: string) => Promise<void>;
}) => {
  const downloadDocs = async (urls: string[]) => {
    await Promise.all(
      urls.map(async (url) => {
        let signedUrl = await getUploadFile(url);
        window.open(signedUrl, "_blank");
      })
    );
  };

  const [allowance, setAllowance] = useState<
    AllowanceRequestsResponse[] | undefined
  >(props.leaveList);

  return (
    <div>
      <h1>Allowance Requests</h1>
      <Table>
        <thead>
          <Tr>
            <Th>Employee Id</Th>
            <Th>Email</Th>
            <Th>allowance</Th>
            <Th>allowance type</Th>

            <Th>date</Th>
            <Th>Bills</Th>
            <Th>Created on</Th>
            <Th>status</Th>
            <Th>accept</Th>
            <Th>reject</Th>
            <Th>payment</Th>
            <Th>your remarks</Th>
            <Th>User remarks</Th>
          </Tr>
        </thead>
        <tbody>
          {allowance &&
            allowance.map((el) => (
              <Tr>
                <Td>{el.userId.employeeId}</Td>
                <Td>{el.userId.email}</Td>
                <Td>
                  <input
                    type="text"
                    value={el.allowance}
                    onChange={(e) => {
                      setAllowance((prev) => {
                        if (prev) {
                          return prev.map((ele) => {
                            if (ele.id === el.id) {
                              return { ...ele, allowance: e.target.value };
                            }
                            return ele;
                          });
                        }
                        return prev;
                      });
                    }}
                  />
                </Td>
                <Td>{el.allowanceType}</Td>

                <Td>{formateDate(new Date(el.date))}</Td>

                <Td>
                  <Div
                    style={{ justifyContent: "center", cursor: "pointer" }}
                    onClick={async () => {
                      await downloadDocs(el.documents);
                    }}
                  >
                    <IoMdDownload color="#ef6262" />
                  </Div>
                </Td>
                <Td>{formateDate(new Date(el.createdAt))}</Td>
                <Td>
                  {el.status == 0
                    ? "pending"
                    : el.status == 1
                    ? "accepted"
                    : "rejected"}
                </Td>

                <Td>
                  <Div
                    onClick={async () => {
                      try {
                        await props.acceptAllowanceRequest(
                          el.id,
                          el.allowance,
                          el.remark
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    style={{ justifyContent: "center", cursor: "pointer" }}
                  >
                    <GoVerified color="#ef6262" />
                  </Div>
                </Td>
                <Td>
                  <Div
                    onClick={async () => {
                      try {
                        await props.rejectAllowanceRequest(
                          el.id,
                          el.allowance,
                          el.remark
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    style={{ justifyContent: "center", cursor: "pointer" }}
                  >
                    <AiFillCloseCircle color="#ef6262" />
                  </Div>
                </Td>
                <Td>
                  <Div
                    style={{ justifyContent: "center", cursor: "pointer" }}
                    onClick={async () => {
                      try {
                        await props.updateAllowancePayment(el.id);
                      } catch (err) {}
                    }}
                  >
                    {el.payment === 1 ? (
                      <Dot style={{ backgroundColor: "green" }} />
                    ) : (
                      <Dot style={{ backgroundColor: "red" }} />
                    )}
                  </Div>
                </Td>
                <Td>
                  <input
                    type="text"
                    value={el.remark}
                    onChange={(e) => {
                      setAllowance((prev) => {
                        if (prev) {
                          return prev.map((ele) => {
                            if (ele.id === el.id) {
                              return { ...ele, remark: e.target.value };
                            }
                            return ele;
                          });
                        }
                        return prev;
                      });
                    }}
                  />
                </Td>

                <Td>
                  <CommentInput
                  style={{width:"200px"}}
                    placeholder="remarks!"
                    value={el.userRemark}
                    readOnly={false}
                  />

                </Td>
              </Tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AllowanceRequestList;
