import styled from "styled-components";

export const TrackerBodyStyle = styled.div`
  height: 100vh;
  margin-left: 300px;
  margin-top: 80px;
  width: 67%;
  display:flex;
  flex-direction: column;
`;

