import { styled } from "styled-components";


export const QuickAccessCardsDiv = styled.div`
  height: 110px;
  width:150px;
  border: 1px solid #ef6262;
  padding: 10px;
  margin: 20px;
  display:flex;
  align-items: center;
  border-radius:10px;
  justify-content: center;
  cursor: pointer;
  background-color: #ef6262;

  &:hover {
    box-shadow: 1px 1px 5px #ef6262;
    background-color: #fff;

  }
`;
