import React from "react";
import { Header, HeaderEnd, NavbarStyle } from "./style";
import { GrNotification } from "react-icons/gr";
import { RiNotification3Line } from "react-icons/ri";

function Navbar(props:{heading?:string}) {
  return (
    <NavbarStyle>
      <Header>
        <p>{props.heading|| "Home"}</p>
      </Header>

      <HeaderEnd>
        <Header>
          <p>Quick Links</p>
        </Header>
        <Header>
          <RiNotification3Line  style={{marginLeft:'10px',marginRight:'15px'}} color="white" />
        </Header>
      </HeaderEnd>
    </NavbarStyle>
  );
}

export default Navbar;
