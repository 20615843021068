import React, { useEffect, useState } from "react";
import { AttendanceInfoBody, InsightLink, RegularizationButton } from "./style";
import { Div } from "../../../../styled/style";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AiOutlineWarning } from "react-icons/ai";
import {
  getGaps,
  getSwipes,
  getSwipesResponse,
} from "../../../../utils/userApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  formatTime,
  formateDate,
  handleAxiosError,
} from "../../../../utils/utils";
import { Spinner } from "../../../../components/Spinner";
import DateSlots from "../../../../components/DateSlots/DateSlots";
import { useMutation } from "@tanstack/react-query";
import { RegularizationInterface, createRegularization } from "../../../../utils/AttendanceApi";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../config/route.config";

function AttendanceInfo() {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  type ValuePiece = Date | null;
  const auth = useSelector((state: RootState) => state.auth);

  type Value = ValuePiece | [ValuePiece, ValuePiece];
  const [value, onChange] = useState<Value>(new Date());
  const [showRegularizeDates, setShowRegularizeDates] = useState(false);

  const [swipeResponse, setSwipeResponse] = useState<getSwipesResponse | null>(
    null
  );

  type MyObjectType = Record<any, number>;

  const [presenceData, setPresenceData] = useState<MyObjectType>({});

  const navigate = useNavigate();


  const [attendance, setAttendance] = useState<{
    present: string[];
    absent: string[];
    leave: string[];
  } | null>(null);

  const { mutateAsync: createRegularizationMutation } = useMutation({
    mutationFn: async (data:RegularizationInterface) =>
      await createRegularization(auth.jwt, data),
    onSuccess: () => {
      setShowRegularizeDates(false);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const tileContent = ({ date }: any) => {
    const previousDay = new Date(date);
    previousDay.setDate(date.getDate() + 1);

    const dateString = previousDay.toISOString().split("T")[0];
    const isPresent = presenceData[dateString];
    let status = "";
    if (isPresent === 0) {
      status = "present";
    } else if (isPresent === 1) {
      status = "absent";
    } else if (isPresent === 2) {
      status = "leave";
    }
    return <div>{status}</div>;
  };

  useEffect(() => {
    async function getSwipesData() {
      setIsLoading(true);
      if (value != null) {
        let date = new Date(value.toString());

        const formattedDate = formateDate(date);

        try {
          const data = await getSwipes(formattedDate, auth.jwt);
          const swipeData = await getGaps(formattedDate, auth.jwt);

          setAttendance({
            present: swipeData.present,
            absent: swipeData.absent,
            leave: swipeData.leave,
          });
          if (swipeData.present.length > 0) {
            for (let date of swipeData.present) {
              presenceData[formateDate(new Date(date))] = 0;
              setPresenceData(presenceData);
            }
            for (let date of swipeData.absent) {
              presenceData[formateDate(new Date(date))] = 1;
              setPresenceData(presenceData);
            }
            for (let date of swipeData.leave) {
              presenceData[formateDate(new Date(date))] = 2;
              setPresenceData(presenceData);
            }
          }
          setSwipeResponse(data);
          setIsLoading(false);
        } catch (err) {
          handleAxiosError(err, dispatch);
        }
      }
    }
    getSwipesData();
  }, [value]);

  const returnSwipeJSX = (data: {
    u_id: string;
    in: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    out: string;
    id: string;
  }) => {
    return (
      <Div
        key={data.id}
        style={{ alignContent: "center", justifyContent: "center" }}
      >
        <p style={{ paddingRight: "20px", color: "#F31559", width: "100px" }}>
          {formatTime(new Date(data.in))}
        </p>
        <p style={{ color: "#F31559", width: "100px" }}>
          {data.out && formatTime(new Date(data.out))}
        </p>
      </Div>
    );
  };

  return (
    <AttendanceInfoBody>
      {isLoading ? (
        <Spinner />
      ) : showRegularizeDates && attendance ? (
        <DateSlots
        elementId=""
        userRemark=""
          adminRemark=""
          remarkEditable={false}
          delete={true}
          dates={attendance.absent}
          onSubmit={createRegularizationMutation}
          userRemarkEditable={true}
          userRemarkVisible={true}
        />
      ) : (
        <>
          <Div>
            <RegularizationButton onClick={()=>navigate(RoutePath.MyRegularization)}>My Regularization</RegularizationButton>
          </Div>
          <Div style={{ justifyContent: "center" }}>
            <AiOutlineWarning size={40} />
            <p>{attendance?.absent.length} gap(s) in attendance</p>
            <InsightLink
              style={{ marginLeft: "20px" }}
              onClick={() => setShowRegularizeDates(true)}
            >
              Regularize
            </InsightLink>
          </Div>
          <Div>
            <Div
              style={{
                flexDirection: "column",
                width: "200px",
                margin: "10px",
              }}
            >
              <Div
                style={{
                  border: "1px solid black",
                  borderWidth: "1px 1px 0px 1px",
                  justifyContent: "center",
                }}
              >
                <p>AVG. WORK HRS</p>
              </Div>
              <Div
                style={{
                  border: "1px solid black",
                  justifyContent: "center",
                  height: "90px",
                }}
              >
                <p style={{ fontWeight: "bold" }}>06:27</p>
              </Div>
            </Div>

            <Div
              style={{
                flexDirection: "column",
                width: "200px",
                margin: "10px",
              }}
            >
              <Div
                style={{
                  border: "1px solid black",
                  borderWidth: "1px 1px 0px 1px",
                  justifyContent: "center",
                }}
              >
                <p>AVG. ACTUAL WORK HRS</p>
              </Div>
              <Div
                style={{
                  border: "1px solid black",
                  justifyContent: "center",
                  height: "90px",
                }}
              >
                <p style={{ fontWeight: "bold" }}>06:27</p>
              </Div>
            </Div>

            <Div
              style={{
                flexDirection: "column",
                width: "200px",
                margin: "10px",
              }}
            >
              <Div
                style={{
                  border: "1px solid black",
                  borderWidth: "1px 1px 0px 1px",
                  justifyContent: "center",
                }}
              >
                <p>PENALTY DAYS</p>
              </Div>

              <Div
                style={{
                  border: "1px solid black",
                  justifyContent: "center",
                  height: "90px",
                }}
              >
                <p style={{ fontWeight: "bold" }}>0</p>
              </Div>
            </Div>
            <Div style={{ flexDirection: "column", justifyContent: "center" }}>
              <InsightLink>+3 INSIGHTS</InsightLink>
            </Div>
          </Div>
          <Div style={{ justifyContent: "center" }}>
            <Div style={{ margin: "10px", fontSize: "16px" }}>
              <Calendar
                tileContent={tileContent}
                onChange={onChange}
                value={value}
              />
            </Div>
            <Div
              style={{
                border: "1px solid black",
                width: "350px",
                margin: "10px",
                flexDirection: "column",
                overflow: "auto",
              }}
            >
              {swipeResponse != null ? (
                swipeResponse.swipes.map((el) => returnSwipeJSX(el))
              ) : (
                <></>
              )}
            </Div>
          </Div>
        </>
      )}
    </AttendanceInfoBody>
  );
}

export default AttendanceInfo;
