import React from "react";
import { LeaveApplicationBodyDiv, Table, Td, Th, Tr } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import {
  getAllEmployeePendingLeaves,
  respondToLeave,
} from "../../../../utils/LeaveApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formateDate } from "../../../../utils/utils";
import { Div } from "../../../../styled/style";
import { SlCalender } from "react-icons/sl";
import { AiFillCloseCircle } from "react-icons/ai";
import { GoVerified } from "react-icons/go";
import { setAlert } from "../../../../features/signIn/AlertSlice";
import { Spinner } from "../../../../components/Spinner";

function LeaveApplicationBody() {
  const dispatch = useDispatch();

  const auth = useSelector((state: RootState) => state.auth);
  const queryClient = useQueryClient();

  const { data: leaves, isLoading } = useQuery({
    queryFn: () => getAllEmployeePendingLeaves(auth.jwt),
    queryKey: ["leave"],
  });

  const { mutateAsync: applyLeaveMutation } = useMutation({
    mutationFn: respondToLeave,
    onSuccess: () => {
      queryClient.invalidateQueries(["leave"]);
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  const applyAction = (action: number, leaveId: string) => {
    applyLeaveMutation({ action, jwt: auth.jwt, leaveId });
  };

  return leaves !== undefined && leaves.length > 0 ? (
    <LeaveApplicationBodyDiv style={{ width: "100%" }}>
      <Table style={{ width: "100%" }}>
        <Tr>
          <Th>Email</Th>
          <Th>Employee Id</Th>
          <Th>contact details</Th>
          <Th>applied on</Th>
          <Th>from</Th>
          <Th>to date</Th>
          <Th>reason</Th>

          <Th>status</Th>
          <Th>accept</Th>
          <Th>reject</Th>

        </Tr>
        {leaves.map((el) => (
          <Tr>
            <Td>{typeof el.userId !== 'string' && el.userId.officialEmail}</Td>
            <Td>{typeof el.userId !== 'string' && el.userId.employeeId}</Td>

            <Td>{el.contactDetails}</Td>
            <Td>{formateDate(new Date(el.createdAt))}</Td>
            <Td>{formateDate(new Date(el.fromDate))}</Td>
            <Td>{formateDate(new Date(el.toDate))}</Td>
            <Td>{el.reason}</Td>

            <Td>
              {el.status === 0 ? (
                <p>pending</p>
              ) : el.status === 1 ? (
                <p>accepted</p>
              ) : (
                <p>rejected</p>
              )}
            </Td>
            <Td>
              <Div
                onClick={() => {
                  dispatch(
                    setAlert({ message: "leave accepted!", visible: true })
                  );
                  applyAction(1, el.id);
                }}
                style={{ justifyContent: "center", cursor: "pointer" }}
              >
                <GoVerified color="#ef6262" />
              </Div>
            </Td>
            <Td>
              <Div
                onClick={() => {
                  dispatch(
                    setAlert({ message: "leave rejected!", visible: true })
                  );
                  applyAction(2, el.id);
                }}
                style={{ justifyContent: "center", cursor: "pointer" }}
              >
                <AiFillCloseCircle color="#ef6262" />
              </Div>
            </Td>
          </Tr>
        ))}
      </Table>
    </LeaveApplicationBodyDiv>
  ) : (
    <LeaveApplicationBodyDiv>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <SlCalender size={300} />
      </Div>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <p>There are no past records of any leave Transactions made by you</p>
      </Div>
    </LeaveApplicationBodyDiv>
  );
}

export default LeaveApplicationBody;
