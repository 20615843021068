import { styled } from "styled-components";

export const ModalBody = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-item: center;
`;

export const ModalSubmitButton = styled.button`
  border: none;
  outline: none;
  height: 30px;
  color: white;
  background-color: #f31559;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
  margin-top: 30px;
`;

export const ModalInput = styled.input`
  height: 40px;
  font-size: 16px;
  padding: 10px;
  border: 1px solid #ef6262;

`;

export const SelectField = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ef6262;
  border-radius: 5px;
`;