import { AdminDashboard } from "../screens/AdminDashboard";
import { AllowanceRequests } from "../screens/AllowanceRequests";
import { Attendance } from "../screens/Attendance";
import { Bands } from "../screens/Bands";
import { ChangePassword } from "../screens/ChangePassword";
import { Employee } from "../screens/Employee";
import { EmployeeAttendance } from "../screens/EmployeeAttendance";
import { Feeds } from "../screens/Feeds";
import { HolidayCalender } from "../screens/HolidayCalender";
import { Home } from "../screens/Home";
import { Leave } from "../screens/Leave";
import { LeaveApplication } from "../screens/LeaveApplication";
import { LeaveBallance } from "../screens/LeaveBallance";
import { Login } from "../screens/Login";
import MyRegularization from "../screens/MyRegularization/container/MyRegularization";
import { Tracker } from "../screens/Tracker";
import { TrackerRequest } from "../screens/TrackerRequest";
import { UserAllowance } from "../screens/UserAllowance";
import { UserRegularization } from "../screens/UserRegularization";
import { VendorRegistration } from "../screens/VendorRegistration";

export const RouteNames = {
  Home: "home",
  Feeds: "feeds",
  LeaveApply: "leaveApply",
  HolidayCalender: "holidayCalender",
  Attendance: "attendance",
  Login: "login",
  Tracker: "tracker",
  AdminDashboard: "adminDashboard",
  TrackerRequest: "trackerRequest",
  Employee: "employee",
  LeaveApplications: "leaveApplications",
  LeaveBallance: "LeaveBallance",
  UserAllowance: "UserAllowance",
  AllowanceRequests: "AllowanceRequests",
  EmployeeAttendance: "EmployeeAttendance",
  Bands: "Bands",
  ChangePassword: 'ChangePassword',
  VendorRegistration:'VendorRegistration',
  MyRegularization: 'MyRegularization',
  UserRegularization: 'UserRegularization'
};

export const RoutePath = {
  Home: "/",
  Feeds: "/feeds",
  LeaveApply: "/leave-apply",
  HolidayCalender: "/holiday-calender",
  Attendance: "/attendance",
  Login: "/login",
  Tracker: "/tracker",
  AdminDashboard: "/admin-dashboard",
  TrackerRequest: "/tracker-request",
  Employee: "/employee",
  LeaveApplications: "/leave-applications",
  LeaveBallance: "/leave-ballance",
  UserAllowance: "/user-allowance",
  AllowanceRequests: "/allowance-requests",
  EmployeeAttendance: "/employee-attendance",
  Bands: "/bands",
  ChangePassword:"/change-password",
  VendorRegistration:'/vendor-registration',
  MyRegularization: '/my-regularization',
  UserRegularization: '/user-regularization'
};

const Routes = [
  {
    element: <Home />,
    path: RoutePath.Home,
    name: RouteNames.Home,
  },
  {
    element: <Feeds />,
    path: RoutePath.Feeds,
    name: RouteNames.Feeds,
  },
  {
    element: <Leave />,
    path: RoutePath.LeaveApply,
    name: RouteNames.LeaveApply,
  },
  {
    element: <HolidayCalender />,
    path: RoutePath.HolidayCalender,
    name: RouteNames.HolidayCalender,
  },
  {
    element: <Attendance />,
    path: RoutePath.Attendance,
    name: RouteNames.Attendance,
  },
  {
    element: <Login />,
    path: RoutePath.Login,
    name: RouteNames.Login,
  },
  {
    element: <Tracker />,
    path: RoutePath.Tracker,
    name: RouteNames.Tracker,
  },
  {
    element: <AdminDashboard />,
    path: RoutePath.AdminDashboard,
    name: RouteNames.AdminDashboard,
  },
  {
    element: <TrackerRequest />,
    path: RoutePath.TrackerRequest,
    name: RouteNames.TrackerRequest,
  },
  {
    element: <Employee />,
    path: RoutePath.Employee,
    name: RouteNames.Employee,
  },
  {
    element: <LeaveApplication />,
    path: RoutePath.LeaveApplications,
    name: RouteNames.LeaveApplications,
  },
  {
    element: <LeaveBallance />,
    path: RoutePath.LeaveBallance,
    name: RouteNames.LeaveBallance,
  },
  {
    element: <UserAllowance />,
    path: RoutePath.UserAllowance,
    name: RouteNames.UserAllowance,
  },
  {
    element: <AllowanceRequests />,
    path: RoutePath.AllowanceRequests,
    name: RouteNames.AllowanceRequests,
  },
  {
    element: <EmployeeAttendance />,
    path: RoutePath.EmployeeAttendance,
    name: RouteNames.EmployeeAttendance,
  },
  {
    element: <Bands />,
    path: RoutePath.Bands,
    name: RouteNames.Bands,
  },
  {
    element: <ChangePassword />,
    path: RoutePath.ChangePassword,
    name: RouteNames.ChangePassword,
  },
  {
    element: <VendorRegistration />,
    path: RoutePath.VendorRegistration,
    name: RouteNames.VendorRegistration,
  },
  {
    element: <MyRegularization />,
    path: RoutePath.MyRegularization,
    name: RouteNames.MyRegularization,
  },
  {
    element: <UserRegularization />,
    path: RoutePath.UserRegularization,
    name: RouteNames.UserRegularization,
  }
];

export default Routes;
