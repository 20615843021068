import styled from "styled-components";

// Styled component for the Thank You message
export const ThankYouContainer = styled.div`
  text-align: center;
  font-family: "Arial", sans-serif;
  background-color: #f0f0f0;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const ThankYouTitle = styled.h1`
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Message = styled.p`
  font-size: 18px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
