import React from "react";
import { Header } from "../../../components/Header";
import { Navbar } from "../../../components/Navbar";
import { Div } from "../../../styled/style";
import { Footer } from "../../../components/Footer";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteMyRegularization,
  getMyRegularization,
} from "../../../utils/AttendanceApi";
import { Spinner } from "../../../components/Spinner";
import DateSlots from "../../../components/DateSlots/DateSlots";
import { MyRegularizationBody } from "./style";
import { Dot } from "../../Employee/components/style";
import { RiDeleteBinLine } from "react-icons/ri";
import axios, { AxiosError } from "axios";

function MyRegularization() {
  const auth = useSelector((state: RootState) => state.auth);
  const queryClient = useQueryClient();
  const [loading, setLoading] = React.useState(false);

  const { data: allRegularization, isLoading: regularizationLoading } =
    useQuery({
      queryFn: () => {
        return getMyRegularization(auth.jwt);
      },
      queryKey: ["allRegularization"],
    });

  const { mutateAsync: deleteRegularizationMutation } = useMutation({
    mutationFn: async (data: { id: string }) =>
      await deleteMyRegularization(auth.jwt, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["allRegularization"]);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  return (
    <Div style={{ flexDirection: "column" }}>
      <Header />
      <Navbar heading="My Regularization" />
      <MyRegularizationBody>
        {regularizationLoading ? (
          <Spinner />
        ) : (
          allRegularization?.map((el, index) => (
            <>
              <h1>
                Regularization {index + 1}{" "}
                {el.status === 2 ? (
                  <Dot style={{ backgroundColor: "green" }} />
                ) : el.status === 0 ? (
                  <Div style={{ justifyContent: "space-between" }}>
                    <Dot style={{ backgroundColor: "orange" }} />
                    {loading ? (
                      <Spinner />
                    ) : (
                      <RiDeleteBinLine
                        size={30}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await deleteRegularizationMutation({ id: el.id });
                            setLoading(false);
                          } catch (e) {
                            setLoading(false);
                          }
                        }}
                      />
                    )}
                  </Div>
                ) : (
                  <Dot style={{ backgroundColor: "red" }} />
                )}
              </h1>

              <DateSlots
                elementId={el.id}
                adminRemark={el.adminRemark}
                remarkEditable={false}
                dates={el.gaps}
                onSubmit={null}
                delete={false}
                userRemark={el.userRemark}
                userRemarkVisible={true}
                userRemarkEditable={false}
              />
            </>
          ))
        )}
      </MyRegularizationBody>

      <Footer />
    </Div>
  );
}

export default MyRegularization;
