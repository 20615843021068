import axios from "axios";
import { connection } from "./utils";
import { Employee } from "./userApi";

export const addPaySlip = async (
  data: {
    month: string;
    year: string;
    employeeId: string;
    payslipUrl: string[];
  },
  jwt: string
): Promise<Employee> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.superAdmin.updatePaySlip}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const delPaySlip = async (
  data: {
    userId: string;
    payslipId: string;
  },
  jwt: string
): Promise<Employee> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.superAdmin.deletePaySlip}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};
