import React, { useState } from "react";
import Select from "react-select";

let colourOptions:({
  value: string;
  label: string;
  color: string;
  isFixed: boolean;
} | {
  value: string;
  label: string;
  color: string;
  isFixed?: undefined;
})[]
= [
  
];

function SingleSelect(props:any) {
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  for(let el of props.leaves){
    let obj = {value: el.id, label: el.email, color: 'black'};
    colourOptions.push(obj);
  }
  return (
    <>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={colourOptions}
        placeholder='Applying to'
        onChange={props.onChange}
      />

      <div
        style={{
          color: "hsl(0, 0%, 40%)",
          display: "inline-block",
          fontSize: 12,
          fontStyle: "italic",
          marginTop: "1em",
        }}
      >
      </div>
    </>
  );
}

export default SingleSelect;
