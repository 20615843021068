import { styled } from "styled-components";

export const LoginFormBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  margin: 0 20px 0 0;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
`;

export const LoginMainForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const LoginInput = styled.input`
  height: 40px;
  font-size: 16px;
  padding: 10px;
`;

export const LoginButton = styled.button`
  flex: 1;
  border-radius: 8px;
  border: none;
  background-color: #f31559;
  color: white;
  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
  cursor: pointer;
`;

export const LoginForgetPassword = styled.p`
  &:hover {
    box-shadow: 5px 5px 8px #f31559;
  }
  color: #f31559;
  cursor: pointer;
  user-select: none;
`;


export const LoginCarouselBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  margin: 0 20px 0 0;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
`;