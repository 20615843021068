import React from "react";
import { Table, Td, Th, Tr } from "./style";
import { BandInterface } from "../../../utils/BandsApi";

const BandList = (props: { BandList: BandInterface[] }) => {
  return (
    <div>
      <Table style={{ width: "100%" }}>
        <Tr>
          <Th>Band Id</Th>
          <Th>Band</Th>
          <Th>Entertainment Reimbursement</Th>
          <Th>LTA</Th>

        </Tr>
        {props.BandList?.map((item) => (
          <Tr>
            <Td>{item.id}</Td>
            <Td>{item.Bands}</Td>
            <Td>{item.EntertainmentReimbursement}</Td>
            <Td>{item.LTA}</Td>
          </Tr>
        ))}
      </Table>
    </div>
  );
};

export default BandList;
