import { styled } from "styled-components";

export const BodyStyle = styled.div`
  height: 100vh;
  margin-left: 300px;
  margin-top: 80px;
  width: 67%;
`;

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
`;

export const ColumnOne = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const ColumnTwo = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const ColumnThree = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Card = styled.div`
  display: flex;
  border: 1px ridge;
  flex-direction: column;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 15px;
  &:hover {
    box-shadow: 1px 1px 5px #ef6262;
  }
`;

export const CardHeading = styled.p`
  padding-left: 10px;
`;

export const Center = styled.div`
  text-align: center;
  justify-content: center;
`;

//  cursor: "pointer", userSelect:"none"
export const QuickAccessLinks = styled.div`
  padding-left: 10px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #FFCACC
    ;
  }
`;

export const QuickAccessTable = styled.div`
  display:flex;
`

