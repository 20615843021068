import { UseMutateAsyncFunction } from "@tanstack/react-query";
import React, { useState } from "react";
import styled from "styled-components";
import { RegularizationInterface } from "../../utils/AttendanceApi";
import { Button } from "../UpdatePassword/style";
import { Div } from "../../styled/style";
import { Spinner } from "../Spinner";

// Define styled components
const DateBox = styled.div`
  display: inline-block;
  background-color: #ef6262;
  color: #fff;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const CommentBoxContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

const CommentInput = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
`;

const CommentButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
`;

// Create a React component
const DateList = ({ dates, onDelete }: { dates: string[]; onDelete: any }) => {
  return (
    <div>
      {dates.map((date: string, index: number) => (
        <DateBox key={index} onClick={() => onDelete(index)}>
          {new Date(date).toLocaleDateString()}
        </DateBox>
      ))}
    </div>
  );
};

// Create the main component
const DateSlots = (props: {
  dates: string[];
  delete: boolean;
  elementId: string;
  adminRemark: string;
  userRemark: string;

  remarkEditable: boolean;
  userRemarkEditable: boolean;
  userRemarkVisible: boolean;

  onSubmit: UseMutateAsyncFunction<
    any,
    unknown,
    RegularizationInterface,
    unknown
  > | null;
}) => {
  const [gaps, setGaps] = useState<string[]>(props.dates);
  const [loading, setLoading] = useState(false);

  const handleDelete = (index: number) => {
    if (props.delete) {
      const newGaps = [...gaps];
      newGaps.splice(index, 1);
      setGaps(newGaps);
    }
  };

  const [comment, setComment] = useState(props.adminRemark);
  const [userRemark, setUserRemark] = useState(props.userRemark);

  const handleCommentChange = (e: any) => {
    setComment(e.target.value);
  };

  return (
    <div>
      <DateList dates={gaps} onDelete={handleDelete} />
      <CommentBoxContainer
        style={
          !props.remarkEditable && props.adminRemark === ""
            ? { display: "none" }
            : {}
        }
      >
        <CommentInput
          placeholder="admin Remarks"
          value={comment}
          onChange={handleCommentChange}
          rows={4}
          readOnly={!props.remarkEditable}
        />
      </CommentBoxContainer>


      <CommentBoxContainer
        style={
          !props.userRemarkVisible
            ? { display: "none" }
            : {}
        }
      >
        <CommentInput
          placeholder="user Remarks"
          value={userRemark}
          onChange={(e)=>setUserRemark(e.target.value)}
          rows={4}
          readOnly={!props.userRemarkEditable}
        />
      </CommentBoxContainer>


      {props.onSubmit != null ? (
        loading ? (
          <Spinner />
        ) : (
          <Div style={{ justifyContent: "space-evenly" }}>
            <Button
              onClick={async () => {
                try {
                  if (props.onSubmit) {
                    setLoading(true);
                    await props.onSubmit({
                      adminRemark: comment,
                      gaps: gaps,
                      status: 2,
                      userId: "",
                      createdAt: "",
                      id: props.elementId,
                      updatedAt: "",
                      userRemark: userRemark
                    });
                    setLoading(false);
                  }
                } catch (err) {
                  setLoading(false);
                  console.log(err);
                }
              }}
            >
              Regularize
            </Button>
            <Button
              onClick={async () => {
                try {
                  if (props.onSubmit) {
                    setLoading(true);

                    await props.onSubmit({
                      adminRemark: comment,
                      gaps: gaps,
                      status: 1,
                      userId: "",
                      createdAt: "",
                      id: props.elementId,
                      updatedAt: "",
                      userRemark: userRemark
                    });
                    setLoading(false);
                  }
                } catch (err) {
                  setLoading(true);

                  console.log(err);
                  setLoading(false);
                }
              }}
            >
              Reject
            </Button>
          </Div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateSlots;
