import React, { useState } from 'react'
import { Div } from '../../../styled/style'
import TrackerHeader from '../components/TrackerHeader'
import TrackerForm from '../components/TrackerForm'
import { Alert } from '../../../components/Alert'
import { ThankYouComponent } from '../../../components/ThankYou'

function Tracker() {

  const [thankYou, setThankYou] = useState(false);
  const handleSubmit = ()=>{
    setThankYou(true);
  }
  return (
    <Div style={{flexDirection:'column'}}>
        <TrackerHeader/>
        <Alert />
        {
          thankYou?<ThankYouComponent message='Your tracker has been successfully submitted.'/>: <TrackerForm handleSubmit={handleSubmit}/>
        }
       
    </Div>
  )
}

export default Tracker