import React, { useEffect, useState } from "react";
import {
  Dot,
  EmployeeBodyDiv,
  EmployeeBodyStyle,
  Table,
  Td,
  Th,
  Tr,
} from "./style";
import {
  Employee,
  getAllEmployee,
  updateEmployee,
  uploadFiles,
} from "../../../utils/userApi";
import { BiUserPin } from "react-icons/bi";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { formatDate } from "react-calendar/dist/cjs/shared/dateFormatter";
import { formateDate } from "../../../utils/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Div } from "../../../styled/style";
import { Spinner } from "../../../components/Spinner";
import { UpdateEmployee } from "../../../components/UpdateEmployee";
import { Button } from "../../../components/UpdateEmployee/style";
import axios, { AxiosError } from "axios";
import { RiBillLine } from "react-icons/ri";
import { Payslip } from "../../../components/Payslip";
import { addPaySlip } from "../../../utils/PaySlipApi";
import { PayslipList } from "../../PayslipList";

function EmployeeList() {
  const auth = useSelector((state: RootState) => state.auth);
  const queryClient = useQueryClient();

  const [updateEmployeeForm, setEmployeeForm] = useState(false);
  const [updatePayslip, setUpdatePayslip] = useState(false);

  const [employeeToBeUpdated, setEmployeeToBeUpdated] =
    useState<Employee | null>(null);

  const { mutateAsync: updateEmployeeMutation } = useMutation({
    mutationFn: async (data: Employee) => await updateEmployee(data, auth.jwt),
    onSuccess: () => {
      queryClient.invalidateQueries(["employeeList"]);
      setEmployeeForm(false);
      setEmployeeToBeUpdated(null);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const { mutateAsync: uploadFilesMutation } = useMutation({
    mutationFn: async (formData: FormData) => await uploadFiles(formData),
    onSuccess: () => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const { mutateAsync: uploadPaySlipMutation } = useMutation({
    mutationFn: async (data: {
      employeeId: string;
      month: string;
      year: string;
      payslipUrls: string[];
    }) =>
      await addPaySlip(
        {
          employeeId: data.employeeId,
          month: data.month,
          year: data.year,
          payslipUrl: data.payslipUrls,
        },
        auth.jwt
      ),
    onSuccess: (data) => {
      setEmployeeToBeUpdated(data);
      queryClient.invalidateQueries(["employeeList"]);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const { data: employeeList, isLoading: userLoading } = useQuery({
    queryFn: () => {
      return getAllEmployee(auth.jwt);
    },
    queryKey: ["employeeList"],
  });

  if (userLoading) {
    return <Spinner />;
  }

  return (
    <EmployeeBodyStyle>
      <EmployeeBodyDiv>
        {updateEmployeeForm && employeeToBeUpdated ? (
          <Div style={{ flexDirection: "column" }}>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                setEmployeeForm(false);
                setEmployeeToBeUpdated(null);
              }}
            >
              Back
            </Button>
            <UpdateEmployee
              user={employeeToBeUpdated}
              updateEmployeeMutation={updateEmployeeMutation}
            />
          </Div>
        ) : employeeToBeUpdated && updatePayslip ? (
          <Div style={{ flexDirection: "column" }}>
            <Button
              style={{ width: "100px" }}
              onClick={() => {
                setUpdatePayslip(false);
              }}
            >
              Back
            </Button>
            <Payslip
              fileUploadAsyc={uploadFilesMutation}
              uploadPaySlipMutation={uploadPaySlipMutation}
              employee={employeeToBeUpdated}
            />

            <PayslipList employee={employeeToBeUpdated} />
          </Div>
        ) : (
          <Table style={{ width: "100%" }}>
            <Tr>
              <Th>name</Th>
              <Th>band</Th>

              <Th>Email</Th>
              <Th>Status</Th>
              <Th>Official Email</Th>
              <Th>Active</Th>
              
              <Th>Employee Id</Th>
              <Th>Update Employee</Th>
              <Th>Upload Payslip</Th>
            </Tr>
            {employeeList?.map((item) => (
              <Tr>
               

                <Td>
                  {typeof item.trackerId !== "string" &&
                    item.trackerId.personalDetails.I.name}
                </Td>
                <Td>{item.Band && typeof item.Band !== 'string' && item.Band.Bands}</Td>

                <Td>{item.email}</Td>
                <Td>{item.status}</Td>
                <Td>{item.officialEmail}</Td>
                <Td>
                  <Div style={{ justifyContent: "center" }}>
                    {item.signIn?.online ? (
                      <Dot style={{ backgroundColor: "green" }} />
                    ) : (
                      <Dot style={{ backgroundColor: "red" }} />
                    )}
                  </Div>
                </Td>
                
                <Td>{item.employeeId}</Td>
                <Td>
                  <BiUserPin
                    onClick={() => {
                      setEmployeeForm(true);
                      setEmployeeToBeUpdated(item);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Td>

                <Td>
                  <RiBillLine
                    onClick={() => {
                      setUpdatePayslip(true);
                      setEmployeeToBeUpdated(item);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Td>
              </Tr>
            ))}
          </Table>
        )}
      </EmployeeBodyDiv>
    </EmployeeBodyStyle>
  );
}

export default EmployeeList;
