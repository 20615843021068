import axios from "axios";
import { connection } from "./utils";
import { Employee } from "./userApi";

export interface AllowanceRequestsResponse {
  id: string;
  allowance: string;
  allowanceType: string;

  userId: Employee;
  date: Date;
  documents: [string];
  status: number;
  payment: number;

  createdAt: string;
  updatedAt: string;
  remark: string;
  userRemark: string;
}

export const getAllPendingAllowanceRequest = async (
  jwt: string
): Promise<AllowanceRequestsResponse[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.superAdmin.allPendingAllowanceRequests}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};


export const updateAllowanceRequest = async (data: {allowanceRequestId:string, action:number, remarks: string, amount: string},jwt: string): Promise<AllowanceRequestsResponse> => {
    const response = await axios.put(`${connection.live}${connection.urls.superAdmin.respondToAllowanceRequest}`,data, {
      headers:{
        Authorization:jwt,
      }
    });
  
    return response.data;
  };


  export const updateRespondAllowancePayment = async (data: {allowanceRequestId:string, action:number},jwt: string): Promise<AllowanceRequestsResponse> => {
    const response = await axios.put(`${connection.live}${connection.urls.superAdmin.respondAllowancePayment}`,data, {
      headers:{
        Authorization:jwt,
      }
    });
  
    return response.data;
  };