import React, { useRef, useState } from "react";
import { Employee } from "../../utils/userApi";
import {
  Button,
  FormField,
  FormTitle,
  Input,
  PayslipFormContainer,
  Form,
  Label,
  Select,
} from "./style";
import { Div } from "../../styled/style";
import { GrAttachment } from "react-icons/gr";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

const Payslip = (props: {
  fileUploadAsyc: UseMutateAsyncFunction<
    string[],
    unknown,
    FormData,
    unknown
  >;
  uploadPaySlipMutation: UseMutateAsyncFunction<
    Employee,
    unknown,
    {
      employeeId: string;
      month: string;
      year: string;
      payslipUrls: string[];
    },
    unknown
  >;
  employee: Employee;
}) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const [files, setFiles] = useState<FileList | null>(null);

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - index
  );

  const handleMonthChange = (event: any) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event: any) => {
    setSelectedYear(event.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      let documents: any = [];
      if (files && files.length > 0) {
        const formDataFile = new FormData();
        for (let i = 0; i < files.length; i++) {
          formDataFile.append("images", files[i]);
        }

        const fileUrls = await props.fileUploadAsyc(formDataFile);
        

        let postPaySlip = await props.uploadPaySlipMutation({
          employeeId: props.employee.id,
          month: selectedMonth,
          payslipUrls: fileUrls,
          year: selectedYear,
        });

        setSelectedMonth("");
        setSelectedYear("");

        setFiles(null);

        console.log(postPaySlip);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <PayslipFormContainer>
      <FormTitle>Add Payslip</FormTitle>
      <Form onSubmit={handleSubmit}>
        <FormField>
          <Label>Month</Label>
          <Select id="month" value={selectedMonth} onChange={handleMonthChange}>
            <option value="">Select Month</option>
            {months.map((month, index) => (
              <option key={index} value={month}>
                {month}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField>
          <Label>Year</Label>
          <Select id="year" value={selectedYear} onChange={handleYearChange}>
            <option value="">Select Year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>

          {selectedMonth && selectedYear && (
            <p>
              Selected Month: {selectedMonth}, Selected Year: {selectedYear}
            </p>
          )}
        </FormField>
        <Div>
          <Label>Payslip</Label>

          <GrAttachment
            style={{ color: "white" }}
            size={30}
            onClick={handleClick}
          />

          <Input
            ref={hiddenFileInput}
            type="file"
            placeholder="Date"
            onChange={(e) => {
              setFiles(e.target.files);
            }}
            style={{ display: "none" }}
            multiple
          />
        </Div>
        <Button type="submit">Add Payslip</Button>
      </Form>
    </PayslipFormContainer>
  );
};

export default Payslip;
