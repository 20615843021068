import { styled } from "styled-components";

export const AttendanceInfoBody = styled.div`
    display:flex;
    flex-direction: column;
    border : 1px solid black;
    margin : 100px 100px 100px 250px;
    
`


export const RegularizationButton = styled.div`
    margin-left: auto;
    color: white;
    background-color: #F31559;
    border: none;
    padding: 8px 10px 8px 15px;
    cursor: pointer;
    &:hover{
        box-shadow: 5px 5px 8px #F31559;

    }
`

// cursor: "pointer", userSelect: "none"
export const InsightLink = styled.p`
    color:  #F31559;
    &:hover{
        box-shadow: 5px 5px 8px #F31559;

    };
    cursor: pointer;
    user-select:none;
`
