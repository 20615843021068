import React from 'react'
import { Header } from '../../../components/Header'
import { Navbar } from '../../../components/Navbar'
import { Div } from '../../../styled/style'
import { Footer } from '../../../components/Footer'
import { AttendanceInfo } from '../components/AttendanceInfo'

function Attendance() {
  return (
    <Div style={{flexDirection:'column'}}>
        <Header/>
        <Navbar heading='Attendance'/>
        <AttendanceInfo/>
        <Footer/>
    </Div>
  )
}

export default Attendance