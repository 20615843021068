import { styled } from "styled-components";

export const AllowanceBody = styled.div`
  margin-left: 300px;
  margin-top: 80px;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const DropdownContainer = styled.div`
  width: 120px;
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.div`
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
`;
// display: ${(props) => (props.isOpen ? 'block' : 'none')};

export const DropdownContent = styled.div`
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const DropdownItem = styled.a`
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  &:hover {
    background-color: #f9f9f9;
  }
`;
