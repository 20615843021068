import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "../../utils/userApi";

const initialState: Employee = {
  role: "",
  trackerId: "",
  email: "",
  password: "",
  status: 0,
  officialEmail: "",
  createdAt: "",
  updatedAt: "",
  joiningDate: "",
  id: "",
  employeeId: "",
  signIn: {
    online: false,
    currentSwipe: "",
  },
  leave: {
    earnedLeave: 0,
    sickLeave: 0,
    casualLeave: 0,
  },
  AllowanceBallance: [
    {
      amount: 0,
      month: "",
      year: "",
    },
  ],
  payslips: [],
  LTABallance: "",
  Band: "",
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<Employee>) => {
      state.officialEmail = action.payload.officialEmail;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setName } = userSlice.actions;

export default userSlice.reducer;
