import React, { useEffect, useState } from "react";
import { GiPayMoney } from "react-icons/gi";
import { TbMoneybag } from "react-icons/tb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FiUsers } from "react-icons/fi";

import {
  BodyDiv,
  BodyStyle,
  Card,
  CardHeading,
  Center,
  ColumnOne,
  ColumnThree,
  ColumnTwo,
  QuickAccessLinks,
  QuickAccessTable,
} from "./style";
import { GrNotes } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { handleAxiosError } from "../../../../utils/utils";
import QuickAccessCards from "../QuickAccessCards/QuickAccessCards";
import { SlCalender } from "react-icons/sl";
import { BsWallet2 } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import { Div } from "../../../../styled/style";
import { BiSolidReport } from "react-icons/bi";
import { MdOutlineHolidayVillage } from "react-icons/md";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  LineChart,
  CartesianGrid,
  Line,
} from "recharts";

const data = [
  { name: "Feb", value: 10 },
  { name: "Mar", value: 15 },
  { name: "Apr", value: 7 },
  { name: "May", value: 20 },
];

const revenueData = [
  { month: "Jan", balance: 10000 },
  { month: "Feb", balance: 12000 },
  { month: "Mar", balance: 15000 },
  { month: "Apr", balance: 18000 },
  { month: "May", balance: 20000 },
  // Add more data points as needed
];

function AdminHomeBody() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const auth = useSelector((state: RootState) => state.auth);

  return (
    <BodyStyle>
      <BodyDiv>
        <Div>
          <QuickAccessCards heading="Users" icon={FiUsers} />
          <QuickAccessCards heading="Events" icon={SlCalender} />
          <QuickAccessCards heading="Payroll" icon={BsWallet2} />
          <QuickAccessCards heading="Accounts" icon={AiOutlineCalculator} />
          <QuickAccessCards heading="Holidays" icon={MdOutlineHolidayVillage} />
          <QuickAccessCards heading="Report" icon={BiSolidReport} />
        </Div>


        <Div style={{flexWrap:'wrap'}}>
          <ColumnOne>
            <h2>Salary Statistics</h2>
            <BarChart width={400} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#ef6262" />
            </BarChart>
          </ColumnOne>

          <ColumnTwo>
            <h2>Revenue</h2>

            <PieChart width={400} height={400}>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#ef6262"
                label
              >
                {/* Customize pie slices */}
                {data.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={`#${(Math.random() * 0x1000000)
                      .toString(16)
                      .substr(-6)}`}
                  />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          </ColumnTwo>

          <ColumnThree>
            <h2>Balance</h2>
            <LineChart
              width={800}
              height={400}
              data={revenueData}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="balance"
                stroke="#ef6262"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ColumnThree>
        </Div>
      </BodyDiv>
    </BodyStyle>
  );
}

export default AdminHomeBody;
