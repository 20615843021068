import styled from "styled-components";

export const EmployeeAttendanceBody = styled.div`
  margin-left: 300px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;


export const Container = styled.div`
  max-width: 400px;
  margin-right: 10px ;
`;

export const Section = styled.div`
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 10px;
`;