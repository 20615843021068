import React, { useState } from "react";
import {
  List,
  ListItem,
  ModalContent,
  ModalOverlay,
  UpdateButton,
} from "./style";
import { LiaWalletSolid } from "react-icons/lia";

const AllowanceAmountList = (props: {
  data: {
    month: string;
    year: string;
    amount: number;
  }[];
  setLeave: (data: {
    month: string;
    year: string;
    amount: number;
}[]) => void
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedData, setUpdatedData] = useState([...props.data]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    props.setLeave(updatedData);
  };

  const handleUpdateAmount = (index: number, newValue: number) => {
    // Create a copy of the data array
    const updatedDataCopy = [...updatedData];
    // Update the amount for the specific item in the copy
    updatedDataCopy[index].amount = newValue;
    // Update the state with the new data
    setUpdatedData(updatedDataCopy);
  };

  return (
    <div>
      <LiaWalletSolid
        size={40}
        color="#ef6262"
        style={{ cursor: "pointer" }}
        onClick={openModal}
      />
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
            <h2>Allowance List</h2>
            <List>
              {updatedData.map((item, index) => (
                <ListItem key={index}>
                  <div style={{ paddingRight: "10px" }}>
                    {item.month} {item.year}
                  </div>
                  <div>
                    Amount:{" "}
                    <input
                      value={item.amount}
                      onChange={(e) =>
                        handleUpdateAmount(index, parseInt(e.target.value))
                      }
                      type="number"
                    />
                  </div>
                </ListItem>
              ))}
            </List>
            <button onClick={closeModal}>Close</button>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default AllowanceAmountList;
