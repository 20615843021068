import React, { useState } from "react";
import { BandsBody } from "./style";
import { Header } from "../../../components/Header";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import BandForm from "../components/BandForm";
import { Div } from "../../../styled/style";
import BandList from "../components/BandList";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BandInterface, addBand, getBands } from "../../../utils/BandsApi";
import axios, { AxiosError } from "axios";
import ErrorsList from "../../../components/Errors/ErrorsList";
import { Spinner } from "../../../components/Spinner";

const Bands = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const queryClient = useQueryClient();
  const [formError, setFormError] = useState<any[]>([]);

  const { mutateAsync: addBandMutation } = useMutation({
    mutationFn: async (data: BandInterface) => await addBand(data, auth.jwt),
    onSuccess: () => {
      setFormError([]);

      queryClient.invalidateQueries(["BandList"]);
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
      }
    },
  });

  const { data: fetchedBandList, isLoading: BandLoading } = useQuery({
    queryFn: () => {
      return getBands(auth.jwt);
    },
    queryKey: ["BandList"],
  });

  

  return (
    <Div>
      <Header />
      <Navbar heading="Bands" />
      <BandsBody>
        <BandForm addBandMutateAsync={addBandMutation}/>
        <ErrorsList error={formError} />
        {
          BandLoading?<Spinner/>:fetchedBandList&&<BandList BandList={fetchedBandList} />
        }
        
      </BandsBody>
      <Footer />
    </Div>
  );
};

export default Bands;
