import { styled } from "styled-components";

export const LeaveBallanceCardStyle = styled.div`
  height: 110px;
  width:300px;
  border: 1px solid #ef6262;
  padding: 10px;
  margin: 20px;
  display:flex;
  align-items: center;
  border-radius:10px;
  
  &:hover {
    box-shadow: 1px 1px 5px #ef6262;
  }
`;

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #ef6262;
  color: white;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;
