import React from 'react'
import { Div } from '../../../styled/style'
import VendorRegistrationForm from '../components/VendorRegistrationForm'
import VendorHeader from '../components/VendorHeader'
import { Footer } from '../../../components/Footer'

const VendorRegistration = () => {
  return (
    <Div style={{flexDirection:'column'}}>
        <VendorHeader/>
        <VendorRegistrationForm/>
    </Div>
  )
}

export default VendorRegistration