import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AlertState {
  visible: boolean;
  message: string;
  success?: boolean;
}

const initialState: AlertState = {
  visible: false,
  message: "",
  success: true,
};

export const alertSlice = createSlice({
  name: "Alert",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<AlertState>) => {
      state.visible = action.payload.visible;
      state.message = action.payload.message;
      if(action.payload.success!==undefined){
        state.success = action.payload.success;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAlert } = alertSlice.actions;

export default alertSlice.reducer;
