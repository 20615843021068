import React, { useState } from "react";
import { ApplyFilterButton, DateInput, FiltersBody } from "./style";
import { Div } from "../../../../styled/style";
import { getUserAttendance } from "../../../../utils/AttendanceApi";

const Filters = (props:{fromDate:string, tillDate: string, setFromDate:(val:string)=>void , setTillDate:(val:string)=>void}) => {

  return (
    <FiltersBody >
      <Div style={{margin:'20px'}}>
        <Div style={{ paddingRight: "19px", flexDirection:'column',justifyContent:'center', alignContent:'center' }}>
          <p style={{ paddingRight: "10px" }}>From Date</p>
          <DateInput
            type="date"
            value={props.fromDate}
            required={true}
            style={props.fromDate === "" ? { color: "red" } : {}}
            onChange={(e) => props.setFromDate(e.target.value)}
          />
        </Div>
        <Div style={{ paddingRight: "19px", flexDirection:'column',justifyContent:'center', alignContent:'center' }}>
          <p style={{ paddingRight: "10px" }}>Till Date</p>
          <DateInput
            type="date"
            value={props.tillDate}
            required={true}
            style={props.tillDate === "" ? { color: "red" } : {}}
            onChange={(e) => props.setTillDate(e.target.value)}
          />
        </Div>
        {/* <ApplyFilterButton>Apply</ApplyFilterButton> */}
      </Div>
    </FiltersBody>
  );
};

export default Filters;
