import styled, { keyframes } from 'styled-components';

// Define a keyframe animation
export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Create a styled component for the loading spinner
export const SpinnerStyled = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  animation: ${spin} 1s ease-in-out infinite;
`;
