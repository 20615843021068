import axios from "axios";
import { connection } from "./utils";
import { Employee } from "./userApi";

export interface getUserAttendance {
  leave: string[];
  present: string[];
  absent: string[];
  leaveSpec: {
    "earned-leave": any[];
    "sick-leave": any[];
    "casual-leave": any[];
  };
  employee: Employee;
}

export interface RegularizationInterface {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  status: number;
  gaps: string[];
  adminRemark: string;
  userRemark: string;
}

export interface UserRegularization {
  id:string;
  adminRemark: string;
  userRemark: string;

  status: number;
  gaps: string[];

  userId: {
    trackerId: {
      personalDetails: {
        I: {
          name: string;
        };
      };
    };
    employeeId: number;
    createdAt: string;

    id: string;
  };
}
export const getUserAttendanceByDate = async (
  data: { fromDate: string; tillDate: string; employeeId: string },
  jwt: string
): Promise<getUserAttendance> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.superAdmin.getAttendance}/${data.fromDate}/${data.tillDate}/${data.employeeId}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getMyRegularization = async (
  jwt: string
): Promise<RegularizationInterface[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.user.getRegularize}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const createRegularization = async (
  jwt: string,
  data: RegularizationInterface
): Promise<RegularizationInterface> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.user.postRegularize}`,
    { dates: data.gaps, userRemark: data.userRemark},
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const putRegularization = async (
  jwt: string,
  data: RegularizationInterface
): Promise<RegularizationInterface> => {
  const response = await axios.put(
    `${connection.live}${connection.urls.superAdmin.putRegularize}`,
    {
      regularizeId: data.id, action: data.status, adminRemark: data.adminRemark
    },
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getUserRegularization = async (
  jwt: string
): Promise<UserRegularization[]> => {
  const response = await axios.get(
    `${connection.live}${connection.urls.superAdmin.getRegularize}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const deleteMyRegularization = async (
  jwt: string,
  data: {id:string}
): Promise<UserRegularization[]> => {
  const response = await axios.delete(
    `${connection.live}${connection.urls.user.deleteRegularize}/${data.id}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

