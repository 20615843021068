import React from "react";
import { Div } from "../../../styled/style";
import { LeaveBallanceCardStyle } from "./style";
import { TbMoneybag } from "react-icons/tb";
import { TfiReload } from "react-icons/tfi";

export const LeaveBallanceCard = (props: {
  heading: string;
  balance: string;
  selected: boolean;
  onClick?: () => void;
}) => {
  return (
    <Div>
      <LeaveBallanceCardStyle
        onClick={props.onClick}
        style={
          props.selected
            ? { backgroundColor: "#ef6262" }
            : { backgroundColor: "white" }
        }
      >
        <p
          style={
            !props.selected
              ? { color: "#ef6262", margin: "30px" }
              : { color: "white", margin: "30px" }
          }
        >
          {props.heading}
        </p>
        <p
          style={
            !props.selected
              ? { color: "#ef6262", margin: "30px" }
              : { color: "white", margin: "30px" }
          }
        >
          <TbMoneybag size={12} />
          {props.balance}
        </p>
      </LeaveBallanceCardStyle>
    </Div>
  );
};
