import axios, { Axios, AxiosError, AxiosStatic } from "axios";
import { Dispatch } from "redux";
import { signOut } from "../features/signIn/authSlice";

export const connection = {
  live: "https://vizlogicdigital.com",
  local: "http://localhost:4000",
  urls: {
    updateTrackerForm: "/user/tracker",
    signIn: "/user/signin",
    superAdmin: {
      getTrackerRequest: "/superAdmin/getTrackerRequest",
      assignEmployeeEmail: "/superAdmin/assignEmployeeEmail",
      getAllEmployee: "/superAdmin/getAllEmployee",
      allPendingLeaves: "/superAdmin/allPendingLeaves",
      respondToLeave: "/superAdmin/respondToLeave",
      allAcceptedLeaves: "/superAdmin/allAcceptedLeaves",
      allPendingAllowanceRequests: "/superAdmin/allPendingAllowanceRequests",
      respondToAllowanceRequest: "/superAdmin/respondToAllowanceRequest",
      getAttendance: "/superAdmin/getAttendance",
      updateEmployee: "/superAdmin/updateEmployee",
      updatePaySlip: "/superAdmin/updatePaySlip",
      deletePaySlip: "/superAdmin/deletePaySlip",
      createBand:"/superAdmin/createBand",
      getBands:"/superAdmin/getBands",
      respondAllowancePayment:"/superAdmin/respondAllowancePayment",
      putRegularize:'/superAdmin/regularize',
      getRegularize:'/superAdmin/regularize',
    },
    user: {
      swipe: "/user/swipe",
      getSwipes: "/user/getSwipes/",
      getGaps: "/user/getGaps/",
      applyLeave: "/user/applyLeave",
      pendingLeave: "/user/pendingLeave",
      acceptedLeave: "/user/acceptedLeave",
      historyLeave: "/user/leaveHistory",
      getEmployeeList: "/user/getEmployeeList",
      getUserData: "/user/getUserData",
      allSickLeaves: "/user/allSickLeaves",
      allEarnedLeaves: "/user/allEarnedLeaves",
      allCasualLeaves: "/user/allCasualLeaves",
      getAllowanceRequest: "/user/getAllowanceRequest",
      raiseAllowancesRequest: "/user/raiseAllowancesRequest",
      upload: "/user/upload",
      getTracker: "/user/getTracker",
      sendUpdatePasswordRequest:'/user/sendUpdatePasswordRequest',
      updatePassword:'/user/updatePassword',
      postRegularize:'/user/regularize',
      getRegularize:'/user/regularize',
      deleteRegularize:'/user/regularize',
      getUploadSignedUrl:'/user/getUploadSignedUrl',
      getUploadedFiles:'/user/getUploadedFiles',
    },
  },
};

export const formateDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatTime = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours}:${minutes}:${seconds}`;
};

export const handleAxiosError = (err: any, dispatch: Dispatch) => {
  if (axios.isAxiosError(err)) {
    const axiosError = err as AxiosError; // Cast err to AxiosError type
    if (axiosError.response) {
      // An error with a response (e.g., status code is not in 2xx range)
      console.log("Axios Error Response:", axiosError.response);
      if (axiosError.response.status == 401) {
        dispatch(signOut());
      }
    } else if (axiosError.request) {
      // Request was made, but no response was received
      console.log("Axios Error Request:", axiosError.request);
    } else {
      // Something else happened during the request
      console.log("Axios Error:", axiosError.message);
    }
  }
};
