import React, { useState } from "react";
import { Div } from "../../../styled/style";
import LoginHeader from "../../Login/components/LoginHeader";
import { Footer } from "../../../components/Footer";
import { UpdatePasswordComponent } from "../../../components/UpdatePassword";
import { useLocation } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { callUpdatePassword } from "../../../utils/userApi";
import ThankYou from "../../../components/ThankYou/ThankYou";

const ChangePassword = () => {
  const [formError, setFormError] = useState<any[]>([]);
  const [showThankyou, setShowThankYou] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let userToken: any = searchParams.get("user");

  const { mutateAsync: allowanceRequestMutation } = useMutation({
    mutationFn: async (password: string) =>
      await callUpdatePassword({ password: password }, userToken),
    onSuccess: () => {
      setShowThankYou(true);
    },
    onError: (err) => {},
  });

  return (
    <Div
      style={{
        flexDirection: "column",
        backgroundColor: "#F6F4EB",
        height: "100vh",
      }}
    >
      <LoginHeader />
      <Div
        style={{
          justifyContent: "center",
          alignContent: "center",
          marginBottom: "50px",
        }}
      >
        {showThankyou ? (
          <ThankYou message="Your Password has been successfully changed."/>
        ) : (
          <UpdatePasswordComponent
            updatePassword={allowanceRequestMutation}
          />
        )}
      </Div>
      <Div style={{ marginLeft: "300px" }}>
        <Footer />
      </Div>
    </Div>
  );
};

export default ChangePassword;
