import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Routes, { RoutePath } from "./config/route.config";
import { useSelector, useDispatch } from "react-redux";
import { login } from "./features/signIn/authSlice";
import { RootState } from "./app/store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Spinner } from "./components/Spinner";
import { getUserData } from "./utils/userApi";
import { setName } from "./features/signIn/userSlice";

function App() {
  const dispatch = useDispatch();
  const router = createBrowserRouter(Routes);
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const jwt = localStorage.getItem("jwt");
    const role = localStorage.getItem("role");

    async function setToken() {
      let escapeFromLogin = [
        RoutePath.Tracker,
        RoutePath.Login,
        RoutePath.ChangePassword,
        RoutePath.VendorRegistration,
      ];
      const set = async () => {
        if (jwt !== null && role !== null) {
          if (role !== "SUPER_ADMIN") {
            try {
              const user = await getUserData(jwt);
              dispatch(setName(user));
            } catch (err) {
              console.log(err);
            }
          }

          Promise.resolve(dispatch(login({ jwt: jwt, role: role })));
        } else if (!escapeFromLogin.includes(window.location.pathname)) {
          window.location.href = "/login";
        }
      };

      await set();
      setLoading(false);
    }
    setToken();
  }, [auth.jwt]);

  // useEffect(() => {
  //   // Check if auth.jwt is not set and not already on the Home route
  //   if (!auth.jwt && window.location.pathname !== '/login') {
  //     window.location.href = '/login';
  //   }
  // }, [auth.jwt]);
  const queryClient = new QueryClient();

  return loading ? (
    <Spinner />
  ) : (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
