import React, { useRef, useState } from "react";
import { Div } from "../../../styled/style";
import { AllowanceForm, AllowanceInput, ApplyAllowanceButton, CommentInput } from "./style";
import { GrAttachment } from "react-icons/gr";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { raiseAllowancesRequest, uploadFiles } from "../../../utils/userApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import axios, { AxiosError } from "axios";
import ErrorsList from "../../../components/Errors/ErrorsList";
import { setAlert } from "../../../features/signIn/AlertSlice";
import { Spinner } from "../../../components/Spinner";

const AddAllowanceForm = (props: {
  allowanceType: string;
  date: string;
  setDate: (date: string) => void;
}) => {
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("");
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const auth = useSelector((state: RootState) => state.auth);
  const [amount, setAmount] = useState("");
  const [files, setFiles] = useState<FileList | null>(null);
  const [formError, setFormError] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: applyAllowanceMutation } = useMutation({
    mutationFn: async (documents: string[]) =>
      await raiseAllowancesRequest(
        {
          raisedAmount: amount,
          date: props.date,
          documents: documents,
          allowanceType: props.allowanceType,
          userRemark: remark
        },
        auth.jwt
      ),
    onSuccess: () => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
      }
    },
  });

  const { mutateAsync: uploadFilesMutation } = useMutation({
    mutationFn: async (formData: FormData) => await uploadFiles(formData),
    onSuccess: () => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const handleClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let documents: any = [];
      if (files && files.length > 0) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          formData.append("images", files[i]);
        }

        const fileUrls = await uploadFilesMutation(formData);

        // for (let f of fileUrls) {
        //   documents.push(f.Location);
        // }
        documents = fileUrls;
      }
      
      await applyAllowanceMutation(documents);
      queryClient.invalidateQueries(["allowanceList"]);
      queryClient.invalidateQueries(["user"]);
      dispatch(setAlert({ message: "Done!", visible: true }));
      setRemark("");
      setAmount("");
      props.setDate("");
      setFiles(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  return (
    <Div>
      <AllowanceForm>
        <Div>
          <Div style={{ flexDirection: "column", padding: "20px" }}>
            <p>Amount</p>
            <AllowanceInput
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              type="text"
              placeholder="Amount"
            />
          </Div>
          <Div style={{ flexDirection: "column", padding: "20px" }}>
            <p>Date</p>
            <AllowanceInput
              value={props.date}
              onChange={(e) => props.setDate(e.target.value)}
              type="date"
              placeholder="Date"
            />
          </Div>
          <Div style={{ flexDirection: "column", padding: "20px" }}>
            <p>Bills ({files ? files.length : 0})</p>
            <Div style={{ cursor: "pointer" }}>
              <GrAttachment onClick={handleClick} />
            </Div>

            <AllowanceInput
              ref={hiddenFileInput}
              type="file"
              placeholder="Date"
              onChange={(e) => {
                console.log(files);

                setFiles(e.target.files);
              }}
              style={{ display: "none" }}
              multiple
            />
          </Div>

          <CommentInput placeholder="remarks!" value ={remark} onChange={(e)=>setRemark(e.target.value)}/>

          <Div style={{ marginTop: "10px" }}>
            {loading ? (
              <Spinner />
            ) : (
              <ApplyAllowanceButton onClick={handleSubmit}>
                Apply
              </ApplyAllowanceButton>
            )}
          </Div>
        </Div>
        <ErrorsList error={formError} />
      </AllowanceForm>
    </Div>
  );
};

export default AddAllowanceForm;
