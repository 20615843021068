import React, { useEffect, useRef, useState } from "react";
import { Div } from "../../../styled/style";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import TrackerList from "../components/TrackerList/TrackerList";
import { Header } from "../../../components/Header";
import { AiOutlineShareAlt } from "react-icons/ai";
import { TrackerBodyStyle } from "./style";
import { Alert } from "../../../components/Alert";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../features/signIn/AlertSlice";
import { AllowanceInput } from "../../UserAllowance/components/style";
import { IoIosAttach } from "react-icons/io";

function TrackerRequest() {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const copyTrackerUrl = () => {
    dispatch(setAlert({ message: "copied!", visible: true }));
    navigator.clipboard.writeText("https://myvizlogic.com/tracker");
  };

  const handleClick = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <Div style={{ flexDirection: "column" }}>
      <Header />
      <Alert />

      <Navbar heading="Tracker Request" />

      <TrackerBodyStyle>
        <Div>
          <Div
            style={{ cursor: "pointer", paddingRight: "50px" }}
            onClick={copyTrackerUrl}
          >
            <AiOutlineShareAlt color="#ef6262" size={50} />
            <p>Share Tracker Link</p>
          </Div>
          <Div style={{}}>
            <Div style={{ cursor: "pointer" }}>
              <IoIosAttach onClick={handleClick} color="#ef6262" size={50} />
            </Div>

            <AllowanceInput
              ref={hiddenFileInput}
              type="file"
              placeholder="Date"
              // onChange={(e) => {

              //   setFiles(e.target.files);
              // }}
              style={{ display: "none" }}
              multiple
            />
            <p>Upload Tracker</p>

          </Div>
        </Div>
        <TrackerList />
      </TrackerBodyStyle>
      <Footer />
    </Div>
  );
}

export default TrackerRequest;
