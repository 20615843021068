import React from "react";
import { Navbar } from "../../../components/Navbar";
import { Header } from "../../../components/Header";
import { CreatePost } from "../components/CreatePost";
import { Footer } from "../../../components/Footer";

function Feeds() {
  return <div style={{ width: "100%" }}>
    <Navbar heading="Navbar"/>
    <Header/>
    <CreatePost/>
    <Footer/>
  </div>;
}

export default Feeds;
