import React from 'react'
import { Div } from '../../../styled/style'
import { LeaveApplyHeader } from '../components/LeaveApplyHeader'

function Leave() {
  return (
    <Div style={{flexDirection:'column'}}>
      <LeaveApplyHeader/>
    </Div>
  )
}

export default Leave