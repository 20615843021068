import React from 'react'
import { SpinnerStyled } from './style'
import { Div } from '../../styled/style'

const Spinner = () => {
  return (
    <Div style={{justifyContent:'center', alignContent:'center'}}>
      <SpinnerStyled />
    </Div>
  )
}

export default Spinner