import React from 'react'
import { Div } from '../../../styled/style'
import { Navbar } from '../../../components/Navbar'
import { Footer } from '../../../components/Footer'
import AdminHeader from '../../../components/AdminHeader/AdminHeader'
import AdminHomeBody from '../components/AdminHomeBody/AdminHomeBody'
import { Header } from '../../../components/Header'

function AdminDashboard() {
  return (
    <Div style={{flexDirection:'column'}}>
        <Header/>
        <Navbar heading='Admin Dashboard'/>
        <div style={{display:'flex'}}>
        <AdminHomeBody/>
        </div>
        <Footer/>
    </Div>
  )
}

export default AdminDashboard