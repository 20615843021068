import React from 'react'
import { Div } from '../../../styled/style'
import logo from '../../../assets/images/logo.png';

function TrackerHeader() {
  return (
    <Div style={{padding:'40px 0px 20px 50px'}}>
        <img width={50} src = {logo} alt=''/>
        <p style={{paddingLeft:'40px', fontWeight:'bold'}}>
            Joining Form
        </p>
    </Div>
  )
}

export default TrackerHeader