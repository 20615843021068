import React, { useState } from "react";
import { QuickAccessCardsDiv } from "./style";
import { Div } from "../../../../styled/style";
import { IconType } from "react-icons";

const QuickAccessCards = (props:{icon:IconType, heading:string}) => {
    const [hover, setHover] = useState(false);
    const handleMouseEnter =()=>{
        setHover(!hover);
    }
  return (
    <QuickAccessCardsDiv onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseEnter}>
      <Div
        style={{
          flexDirection: "column",
        }}
      >
        <Div style={{ justifyContent: "center", alignContent: "center" }}>
          <props.icon size={30} style={!hover?{color:"white"}:{color:"black"}}/>
        </Div>
        <p style={!hover?{color:"white"}:{color:"black"}}>{props.heading}</p>
      </Div>
    </QuickAccessCardsDiv>
  );
};

export default QuickAccessCards;
