import React, { useState } from "react";
import { Div } from "../../../styled/style";
import { Navbar } from "../../../components/Navbar";
import { Header } from "../../../components/Header";
import { Footer } from "../../../components/Footer";
import LeaveApplicationBody from "../components/LeaveApplicationBody/LeaveApplicationBody";
import { LCHeader, LCHeaderButton } from "../../Leave/components/LeaveApplyHeader/styled";
import LeaveGranted from "../components/LeaveGranted/LeaveGranted";
import { Alert } from "../../../components/Alert";

function LeaveApplication() {
  const [buttonClicked, setButtonClicked] = useState(0);

  return (
    <Div style={{ flexDirection: "column" }}>
      <Navbar heading="Leave Application" />
      <Header />
      <Alert />

      <LCHeader>
        <LCHeaderButton
          style={
            buttonClicked === 0
              ? {
                  borderRadius: "8px 0 0 8px",
                  backgroundColor: "grey",
                  color: "white",
                }
              : { borderRadius: "8px 0 0 8px" }
          }
          onClick={()=>{setButtonClicked(0)}}

        >
          Apply
        </LCHeaderButton>
      
        <LCHeaderButton
          style={
            buttonClicked === 2
              ? {
                  borderRadius: "0px 8px 8px 0px",
                  backgroundColor: "grey",
                  color: "white",
                }
              : { borderRadius: "0px 8px 8px 0px" }
          }
          onClick={()=>{setButtonClicked(2)}}
          
        >
          History
        </LCHeaderButton>
      </LCHeader>
     
      {buttonClicked === 0? <LeaveApplicationBody/>:<LeaveGranted/>}
      <Footer />
    </Div>
  );
}

export default LeaveApplication;
