import { styled } from "styled-components";

export const FiltersBody = styled.div`
 
  width: 67%;
  display: flex;
  justify-content:center;
`;

export const DateInput = styled.input`
  border: none;
  outline: none;
  border-bottom: 2px solid #ef6262;
`;

export const ApplyFilterButton = styled.div`
  background-color: #ef6262;
  border: none;
  color: white;
  padding: 30px;
  margin: 10px;
  border-radius: 10px;

  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
`;
