import React, { useEffect, useState } from "react";
import { AllowanceRequestsBody } from "./style";
import { Navbar } from "../../../components/Navbar";
import { Header } from "../../../components/Header";
import { Div } from "../../../styled/style";
import { Footer } from "../../../components/Footer";
import { LeaveBallanceCard } from "../../LeaveBallance/components/LeaveBallanceCard";
import AllowanceRequestList from "../components/AllowanceRequestList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllPendingAllowanceRequest, updateAllowanceRequest, updateRespondAllowancePayment } from "../../../utils/AllowanceApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import axios, { AxiosError } from "axios";
import { Alert } from "../../../components/Alert";
import { setAlert } from "../../../features/signIn/AlertSlice";
import { Spinner } from "../../../components/Spinner";

const AllowanceRequests = () => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();
  const auth = useSelector((state: RootState) => state.auth);
  const [allowanceAmount, setAllowanceAmount] = useState(0);
  
  const { data: allAllowancesRequest, isLoading: allowanceListLoading } = useQuery({
    queryFn: () => {
      return getAllPendingAllowanceRequest(auth.jwt);
    },
    queryKey: ["allAllowanceRequests"],
  });

  

  useEffect(()=>{
    let sum = 0;
    allAllowancesRequest?.map(el=>{
      sum+=parseInt(el.allowance);
    });
    setAllowanceAmount(sum);
  },[allAllowancesRequest])

  const { mutateAsync: allowanceRequestMutation } = useMutation({
    mutationFn: async (data:{action:number, allowanceRequestId:string, amount: string, remark: string}) =>
      await updateAllowanceRequest(
        {action:data.action, allowanceRequestId:data.allowanceRequestId, amount: data.amount, remarks:data.remark},
        auth.jwt
      ),
    onSuccess: () => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        
        dispatch(setAlert({ message:errors.errors[0].message, visible: true, success:false }));

      }
    },
  });


  const { mutateAsync: allowancePaymentMutation } = useMutation({
    mutationFn: async (data:{action:number, allowanceRequestId:string}) =>
      await updateRespondAllowancePayment(
        {action:data.action, allowanceRequestId:data.allowanceRequestId},
        auth.jwt
      ),
    onSuccess: () => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });


  const acceptAllowanceRequest = async(allowanceRequestId:string, amount: string, remarks:string)=>{
    await allowanceRequestMutation({action:1, allowanceRequestId:allowanceRequestId,amount: amount, remark: remarks});
    dispatch(setAlert({ message: "Allowance Accepted!", visible: true }));

    queryClient.invalidateQueries(["allAllowanceRequests"]);
  }

  const rejectAllowanceRequest = async(allowanceRequestId:string, amount: string, remarks:string)=>{
    await allowanceRequestMutation({action:2, allowanceRequestId:allowanceRequestId,amount: amount, remark: remarks});
    dispatch(setAlert({ message: "Allowance rejected!", visible: true }));

    queryClient.invalidateQueries(["allAllowanceRequests"]);
  }

  const updateAllowancePayment = async(allowanceRequestId:string)=>{
    await allowancePaymentMutation({action:1, allowanceRequestId:allowanceRequestId});
    dispatch(setAlert({ message: "Allowance Payment updated!", visible: true }));

    queryClient.invalidateQueries(["allAllowanceRequests"]);
  }



  return (
    <Div style={{ flexDirection: "column" }}>
      <Navbar heading="Allowance Requests" />
      <Header />
      <Alert />

      {allowanceListLoading?<Spinner/>:<AllowanceRequestsBody>
        <Div>
          <LeaveBallanceCard
            heading="Total Requests"
            balance={allAllowancesRequest?allAllowancesRequest.length+'':'-'}
            selected={true}
          />
          <LeaveBallanceCard
            heading="Total Cost"
            balance={allowanceAmount+''}
            selected={true}
          />
        </Div>
        <AllowanceRequestList leaveList={allAllowancesRequest} acceptAllowanceRequest={acceptAllowanceRequest} rejectAllowanceRequest={rejectAllowanceRequest} updateAllowancePayment={updateAllowancePayment}/>
      </AllowanceRequestsBody>}
      <Footer />
    </Div>
  );
};

export default AllowanceRequests;
