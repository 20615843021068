import React from 'react'
import { Message, ThankYouContainer, ThankYouTitle } from './style'

const ThankYou = (props:{message:string}) => {
  return (
    <ThankYouContainer>
      <ThankYouTitle>Thank You!</ThankYouTitle>
      <Message>{props.message}</Message>
    </ThankYouContainer>
  )
}

export default ThankYou