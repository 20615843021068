import { styled } from "styled-components";

export const TrackerFormBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

export const Heading = styled.p`
  font-size: 29px;
  font-weight: bold;
  background-color: #f31559;
  color: white;
`;

export const SubHeading = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const TrackerInput = styled.input`
  height: 40px;
  font-size: 16px;
  padding: 10px;
  margin: 10px 10px 20px 0px;
`;

export const TrackerButton = styled.button`
  flex: 1;
  border-radius: 8px;
  border: none;
  background-color: #f31559;
  color: white;
  &:hover {
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
  }
  cursor: pointer;
  height:40px;
`;



export const Select = styled.select`
  /* Add your custom styling here */
  padding: 5px;
  height: 40px;
  margin: 10px 10px 20px 0px;

  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const AddComponent = styled.button`
flex: 1;
border-radius: 4px;
border: none;
background-color: #f31559;
color: white;
&:hover {
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.3);
}
cursor: pointer;
height:40px;
width:100px;
`;
