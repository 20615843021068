import React from "react";
import {
  PayslipItem,
  PayslipListContainer,
  PayslipTitle,
  PayslipUrlItem,
  PayslipUrlsList,
} from "./style";
import { Employee, getUploadFile } from "../../utils/userApi";
import { IoMdDownload } from "react-icons/io";

const PayslipList = (props: { employee: Employee }) => {
  const downloadDocs = async (urls: string[]) => {
    await Promise.all(
      urls.map(async (url) => {
        let signedUrl = await getUploadFile(url);
        window.open(signedUrl, "_blank");
      })
    );
  };
  return (
    <PayslipListContainer>
      {props.employee.payslips.map((payslip) => (
        <PayslipItem key={payslip.id}>
          <PayslipTitle>Year: {payslip.year}</PayslipTitle>
          <PayslipTitle>Month: {payslip.month}</PayslipTitle>

          <PayslipUrlsList
            style={{ justifyContent: "center", cursor: "pointer" }}
            onClick={async () => {
              await downloadDocs(payslip.payslipUrl);
            }}
          >
            {payslip.payslipUrl.length} <IoMdDownload color="#ef6262" />
          </PayslipUrlsList>
        </PayslipItem>
      ))}
    </PayslipListContainer>
  );
};

export default PayslipList;
