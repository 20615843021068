import React, { useState } from "react";
import { Button, FormContainer, InputField, InputLabel } from "./style";
import axios, { AxiosError } from "axios";
import ErrorsList from "../Errors/ErrorsList";
import { UseMutateAsyncFunction } from "@tanstack/react-query";

const UpdatePasswordComponent = (props: {
  updatePassword: UseMutateAsyncFunction<{}, unknown, string, unknown>;
}) => {
  const [formError, setFormError] = useState<any[]>([]);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (password !== rePassword) {
        setFormError([
          {
            message: "Both passwords do not match!",
          },
        ]);
      } else {
        await props.updatePassword(password);
        setFormError([]);
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
        setFormError(errors.errors);
      }
    }
  };

  return (
    <FormContainer>
      <h2>User Employee Details</h2>
      <form onSubmit={handleSubmit}>
        <InputLabel>Password</InputLabel>
        <InputField
          type="password"
          name="role"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <InputLabel>Re-enter Password</InputLabel>
        <InputField
          type="password"
          name="role"
          value={rePassword}
          onChange={(e) => setRePassword(e.target.value)}
        />
        <ErrorsList error={formError} />

        <Button type="submit">Update</Button>
      </form>
    </FormContainer>
  );
};

export default UpdatePasswordComponent;
