import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  RegularizationInterface,
  getUserRegularization,
  putRegularization,
} from "../../../utils/AttendanceApi";
import { UserRegularizationBody } from "./style";
import { Spinner } from "../../../components/Spinner";
import { Div } from "../../../styled/style";
import { Header } from "../../../components/Header";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import DateSlots from "../../../components/DateSlots/DateSlots";
import axios, { AxiosError } from "axios";
import { Alert } from "../../../components/Alert";

const UserRegularization = () => {
  const auth = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { data: allRegularization, isLoading: allRegularizationLoading } =
    useQuery({
      queryFn: () => {
        return getUserRegularization(auth.jwt);
      },
      queryKey: ["allRegularization"],
    });

  const { mutateAsync: createRegularizationMutation } = useMutation({
    mutationFn: async (data: RegularizationInterface) =>
      await putRegularization(auth.jwt, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["allRegularization"]);
      dispatch({ type: "success", message: "Regularization Approved" });
    },
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  return (
    <Div style={{ flexDirection: "column" }}>
      <Navbar heading="User Regularization" />
      <Header />
      <Alert />
      <UserRegularizationBody>
        {allRegularizationLoading ? (
          <Spinner />
        ) : (
          allRegularization?.map((el, index) => (
            <>
              <h2>Regularization {index + 1}</h2>
              <p>
                Status:{" "}
                {el.status === 2
                  ? "Approved"
                  : el.status === 0
                  ? "Pending"
                  : "rejected"}
              </p>
              <p>Status: {el.userId.employeeId}</p>
              <p>Status: {el.userId.trackerId.personalDetails.I.name}</p>

              <DateSlots
                dates={el.gaps}
                adminRemark={el.adminRemark}
                remarkEditable={true}
                delete={false}
                onSubmit={createRegularizationMutation}
                elementId={el.id}
                userRemarkVisible={true}
                userRemarkEditable={false}
                userRemark={el.userRemark}
              />
            </>
          ))
        )}
      </UserRegularizationBody>
      <Footer />
    </Div>
  );
};

export default UserRegularization;
