import React from "react";
import logo from "../../../../assets/images/logo.png";
import { CgProfile } from "react-icons/cg";
import {
  CreatePostHeader,
  CreatePostHeading,
  CreatePostParent,
  CreatePostSidebar,
  SidebarButton,
  Vr,
  CreatePostButton,
  PostCard,
  PostCardHeader,
  PostCardBody,
} from "./style";
import { Div } from "../../../../styled/style";
import { AiOutlineComment, AiOutlineLike } from "react-icons/ai";

function CreatePost() {
  return (
    <CreatePostParent>
      <CreatePostSidebar>
        <SidebarButton>
          <p>All Feeds</p>
        </SidebarButton>

        <SidebarButton>
          <p>Everyone</p>
        </SidebarButton>

        <SidebarButton>
          <p>Events</p>
        </SidebarButton>

        <SidebarButton>
          <p>Company News</p>
        </SidebarButton>

        <SidebarButton>
          <p>Appreciations</p>
        </SidebarButton>
      </CreatePostSidebar>
      <CreatePostHeader>
        <Div style={{ flexDirection: "column" }}>
          <CreatePostHeading>All Feeds</CreatePostHeading>
          <Vr />

          <CreatePostHeading>Groups</CreatePostHeading>
        </Div>
        <Div style={{ paddingLeft: "900px" }}>
          <CreatePostButton>Create Post</CreatePostButton>
        </Div>
      </CreatePostHeader>
      <Div style={{flexDirection: 'column', margin:'200px 100px 0px 600px'}}>
        <Div>
          <PostCard>
            <PostCardHeader style={{ alignContent: "center" }}>
              <Div style={{ padding: "5px" }}>
                <img src={logo} alt="logo" width={40} />
              </Div>

              <Div style={{ marginLeft: "420px" }}>
                <p>12 july 2023</p>
              </Div>
            </PostCardHeader>
            <PostCardBody>
              <Div style={{ padding: "10px" }}>
                <img src={logo} alt="logo" width={120} />
              </Div>
              <Div
                style={{
                  padding: "10px",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                  height: "100px",
                }}
              >
                <CgProfile size={50} style={{ padding: "5px" }} />
                <p>Happy Birthday, Kangana Aggarwal!</p>
              </Div>
            </PostCardBody>

            <Div style={{ flexDirection: "row" }}>
              <Div
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  paddingRight: "10px",
                }}
              >
                <AiOutlineLike size={40} />
                <p>0 Likes</p>
              </Div>
              <Div style={{ justifyContent: "center", alignContent: "center" }}>
                <AiOutlineComment size={40} />
                <p>0 Comments</p>
              </Div>
            </Div>
          </PostCard>
        </Div>

        <Div>
          <PostCard>
            <PostCardHeader style={{ alignContent: "center" }}>
              <Div style={{ padding: "5px" }}>
                <img src={logo} alt="logo" width={40} />
              </Div>

              <Div style={{ marginLeft: "420px" }}>
                <p>12 july 2023</p>
              </Div>
            </PostCardHeader>
            <PostCardBody>
              <Div style={{ padding: "10px" }}>
                <img src={logo} alt="logo" width={120} />
              </Div>
              <Div
                style={{
                  padding: "10px",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                  height: "100px",
                }}
              >
                <CgProfile size={50} style={{ padding: "5px" }} />
                <p>Happy Birthday, Kangana Aggarwal!</p>
              </Div>
            </PostCardBody>
            <Div style={{ flexDirection: "row" }}>
              <Div
                style={{
                  justifyContent: "center",
                  alignContent: "center",
                  paddingRight: "10px",
                }}
              >
                <AiOutlineLike size={40} />
                <p>0 Likes</p>
              </Div>
              <Div style={{ justifyContent: "center", alignContent: "center" }}>
                <AiOutlineComment size={40} />
                <p>0 Comments</p>
              </Div>
            </Div>
          </PostCard>
        </Div>
      </Div>
    </CreatePostParent>
  );
}

export default CreatePost;
