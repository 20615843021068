import axios from "axios";
import { connection } from "./utils";
import { Employee } from "./userApi";

export interface BandInterface {
  id: string;
  Bands: string;
  LTA: string;
  EntertainmentReimbursement: string;
  createdAt: string;
  updatedAt: string;
}

export const addBand = async (
  data: {
    Bands: string;
    LTA: string;
    EntertainmentReimbursement: string;
  },
  jwt: string
): Promise<BandInterface> => {
  const response = await axios.post(
    `${connection.live}${connection.urls.superAdmin.createBand}`,
    data,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );

  return response.data;
};

export const getBands = async (jwt: string): Promise<BandInterface[]> => {
  const putData = await axios.get(
    `${connection.live}${connection.urls.superAdmin.getBands}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );
  return putData.data;
};
