import React, { useState } from "react";
import { Div } from "../../../styled/style";
import { Footer } from "../../../components/Footer";
import LoginHeader from "../components/LoginHeader";
import LoginForm from "../components/LoginForm";
import LoginCarousel from "../components/LoginCarousel";
import EmailToChangePassword from "../components/EmailToChangePassword";

function Login() {
  const [showChangePasswordEmail, setShowPasswordEmail] = useState(false);
  return (
    <Div
      style={{
        flexDirection: "column",
        backgroundColor: "#F6F4EB",
        height: "100vh",
      }}
    >
      <LoginHeader />
      <Div
        style={{
          justifyContent: "center",
          alignContent: "center",
          marginBottom: "50px",
        }}
      >
        {!showChangePasswordEmail ? <LoginForm onForgetPasswordClick={()=>setShowPasswordEmail(true)} /> : <EmailToChangePassword backToLogin={()=>setShowPasswordEmail(false)}/>}
        <LoginCarousel />
      </Div>
      <Div style={{ marginLeft: "300px" }}>
        <Footer />
      </Div>
    </Div>
  );
}

export default Login;
