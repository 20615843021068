import { styled } from "styled-components";

export const TrackerBodyStyle = styled.div`
  height: 100vh;
  
  width: 67%;
`;

export const TrackerBodyDiv = styled.div`
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
`;

export const TrackerListForm = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
  gap: 10px;
  background-color: #2196f3;
  padding: 10px;
`;

export const TrackerDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
`;

export const TrackerP = styled.p`
  font-weight: bold;
  font-size: 30px;
`;

export const TrackerHeading = styled.p`
  font-weight: bold;
  font-size: 30px;
`;

export const Table = styled.table`
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #ef6262;
  color: white;
`;

export const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`;