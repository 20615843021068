import React, { useState } from "react";
import { Div } from "../../../styled/style";
import { Header } from "../../../components/Header";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import EmployeeList from "../components/EmployeeList/EmployeeList";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getAllEmployee } from "../../../utils/userApi";
import Filters from "../components/Filters/Filters";
import {
  getUserAttendance,
  getUserAttendanceByDate,
} from "../../../utils/AttendanceApi";
import axios, { AxiosError } from "axios";
import { UserAttendanceData } from "../../UserAttendanceData";
import {
  Container,
  EmployeeAttendanceBody,
  List,
  ListItem,
  Section,
  Title,
} from "./style";
import { ApplyAllowanceButton } from "../../UserAllowance/components/style";
import { Spinner } from "../../../components/Spinner";
import { formateDate } from "../../../utils/utils";

const EmployeeAttendance = () => {
  const [fromDate, setFromDate] = useState("");
  const [tillDate, setTillDate] = useState("");
  const [showAttendance, setShowAttendance] = useState(false);
  const [AttendanceData, setAttendanceData] =
    useState<getUserAttendance | null>(null);
  const queryClient = useQueryClient();

  const auth = useSelector((state: RootState) => state.auth);

  const { data: user, isLoading: userLoading } = useQuery({
    queryFn: () => {
      return getAllEmployee(auth.jwt);
    },
    queryKey: ["user"],
  });

  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: getUserAttendanceByEmpId } = useMutation({
    mutationFn: async (data: {
      fromDate: string;
      tillDate: string;
      employeeId: string;
    }) =>
      await getUserAttendanceByDate(
        {
          employeeId: data.employeeId,
          fromDate: data.fromDate,
          tillDate: data.tillDate,
        },
        auth.jwt
      ),
    onSuccess: (data) => {},
    onError: (err) => {
      if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        let errors: any = axiosError.response?.data;
      }
    },
  });

  const handleEmployeeClick = async (employeeId: string) => {
    try {
      setIsLoading(true);
      const getAttendance = await getUserAttendanceByEmpId({
        employeeId,
        fromDate,
        tillDate,
      });
      setAttendanceData(getAttendance);
      setShowAttendance(true);
      console.log(getAttendance, showAttendance);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Div style={{ flexDirection: "column" }}>
      <Header />
      <Navbar heading="Employee's Attendance" />
      {userLoading || isLoading ? (
        <Spinner />
      ) : (
        <EmployeeAttendanceBody>
          {showAttendance ? (
            <Div style={{ flexDirection: "column" }}>
              <ApplyAllowanceButton
                style={{ width: "20%" }}
                onClick={() => {
                  setShowAttendance(false);
                }}
              >
                Back
              </ApplyAllowanceButton>
              <Div>
                <Container>
                  <Section>
                    <Title>Absent</Title>
                    <List>
                      {AttendanceData &&
                        AttendanceData.absent.map((item, index) => (
                          <ListItem key={index}>{formateDate(new Date(item))}</ListItem>
                        ))}
                    </List>
                  </Section>
                  <Section>
                    <Title>Present</Title>
                    <List>
                      {AttendanceData &&
                        AttendanceData.present.map((item, index) => (
                          <ListItem key={index}>{formateDate(new Date(item))}</ListItem>
                        ))}
                    </List>
                  </Section>
                  <Section>
                    <Title>Leave</Title>
                    <List>
                      {AttendanceData &&
                        AttendanceData.leave.map((item, index) => (
                          <ListItem key={index}>{formateDate(new Date(item))}</ListItem>
                        ))}
                    </List>
                  </Section>
                  <Section>
                    <Title>Leave Specifications</Title>
                    <List>
                      <strong>{"casual-leave"}</strong>:
                      {AttendanceData &&
                        AttendanceData.leaveSpec["casual-leave"].map((key) => (
                          <ListItem key={"casual-leave"}>{formateDate(new Date(key))}</ListItem>
                        ))}
                    </List>
                    <List>
                      <strong>{"earned-leave"}</strong>:
                      {AttendanceData &&
                        AttendanceData.leaveSpec["earned-leave"].map((key) => (
                          <ListItem key={"earned-leave"}>{formateDate(new Date(key))}</ListItem>
                        ))}
                    </List>
                    <List>
                      <strong>{"sick-leave"}</strong>:
                      {AttendanceData &&
                        AttendanceData.leaveSpec["sick-leave"].map((key) => (
                          <ListItem key={"sick-leave"}>{key}</ListItem>
                        ))}
                    </List>
                  </Section>
                </Container>
                {AttendanceData && (
                  <UserAttendanceData attendanceData={AttendanceData} />
                )}
              </Div>
            </Div>
          ) : (
            <div>
              <Filters
                fromDate={fromDate}
                setFromDate={setFromDate}
                tillDate={tillDate}
                setTillDate={setTillDate}
              />
              <EmployeeList
                employeeList={user}
                handleEmployeeClick={handleEmployeeClick}
              />
            </div>
          )}
        </EmployeeAttendanceBody>
      )}
      <Footer />
    </Div>
  );
};

export default EmployeeAttendance;
