import axios from "axios";
import { connection } from "./utils";
import { Employee } from "./userApi";

export interface postLeave{
    leaveType: string;
    toDate: string;
    fromDate: string;
    applyTo: string;
    ccTo: [string]|[];
    contactDetails: string;
    reason: string;
    status?: number;
    jwt?:string
}

export interface GetLeave{
    id: string;
    leaveType: string;
    toDate: string;
    fromDate: string;
    applyTo: string;
    ccTo: [string] | [];
    contactDetails: string;
    reason: string;
    userId: string| Employee;
    createdAt: string;
    updatedAt: string;
    status: number;
}

export interface respondToLeaveInput{
  leaveId: string;
  action: number;
  jwt: string;
}

export const applyLeave = async(data:postLeave):Promise<GetLeave>=>{
        const putData = await axios.post(`${connection.live}${connection.urls.user.applyLeave}`, data,{
            headers:{
              Authorization:data.jwt,
            }
          });
        return putData.data;
   
}   

export const getPendingLeave = async(jwt: string):Promise<GetLeave[]>=>{
    const putData = await axios.get(`${connection.live}${connection.urls.user.pendingLeave}`,{
        headers:{
          Authorization:jwt,
        }
      });
    return putData.data;
}

export const getHistoryLeave = async(jwt: string):Promise<GetLeave[]>=>{
  const putData = await axios.get(`${connection.live}${connection.urls.user.historyLeave}`,{
      headers:{
        Authorization:jwt,
      }
    });
  return putData.data;
}


export const getAccepted = async(jwt: string):Promise<GetLeave[]>=>{
    const putData = await axios.get(`${connection.live}${connection.urls.user.acceptedLeave}`,{
        headers:{
          Authorization:jwt,
        }
      });
    return putData.data;
}


export const getAllEmployeePendingLeaves = async (jwt: string): Promise<GetLeave[]>=>{
  const putData = await axios.get(`${connection.live}${connection.urls.superAdmin.allPendingLeaves}`,{
    headers:{
      Authorization:jwt,
    }
  });
return putData.data;
}


export const getAllEmployeeAcceptedLeaves = async (jwt: string): Promise<GetLeave[]>=>{
  const putData = await axios.get(`${connection.live}${connection.urls.superAdmin.allAcceptedLeaves}`,{
    headers:{
      Authorization:jwt,
    }
  });
return putData.data;
}

export const respondToLeave = async (data:respondToLeaveInput):Promise<GetLeave>=>{
  const putData = await axios.put(`${connection.live}${connection.urls.superAdmin.respondToLeave}`,data, {
    headers:{
      Authorization:data.jwt,
    }
  });
  return putData.data;
}