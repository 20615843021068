import React from 'react'
import { LeaveApplicationBodyDiv, Table, Td, Th, Tr } from '../LeaveApplicationBody/style'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { getAllEmployeeAcceptedLeaves } from '../../../../utils/LeaveApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { formateDate } from '../../../../utils/utils';
import { Div } from '../../../../styled/style';
import { SlCalender } from 'react-icons/sl';
import { Spinner } from '../../../../components/Spinner';

function LeaveGranted() {
    const auth = useSelector((state: RootState) => state.auth);
    const queryClient = useQueryClient();

    
    const { data: leaves, isLoading } = useQuery({
        queryFn: () => getAllEmployeeAcceptedLeaves(auth.jwt),
        queryKey: ["leave"],
      });

      if (isLoading) {
        return <Spinner/>;
      }
    
      return leaves !== undefined && leaves.length > 0 ? (

        <LeaveApplicationBodyDiv style={{width:'100%'}}>
    <Table style={{width:'100%'}}>
          <Tr>
            <Th>Email</Th>
            <Th>Employee Id</Th>
            <Th>contact details</Th>
            <Th>applied on</Th>
            <Th>from</Th>
            <Th>to date</Th>
            <Th>status</Th>
            <Th>reason</Th>
           
          </Tr>
          {leaves.map((el) => (
            <Tr>
              <Td>{typeof el.userId!== 'string'&& el.userId.officialEmail}</Td>
              <Td>{typeof el.userId!== 'string'&& el.userId.employeeId}</Td>

              <Td>{el.contactDetails}</Td>
              <Td>{formateDate(new Date(el.createdAt))}</Td>
              <Td>{formateDate(new Date(el.fromDate))}</Td>
              <Td>{formateDate(new Date(el.toDate))}</Td>
              <Td>{el.status === 0?<p>pending</p>:el.status === 1?<p>accepted</p>:<p>rejected</p>}</Td>
              <Td>{el.reason}</Td>
            </Tr>
          ))}
        </Table>
        </LeaveApplicationBodyDiv>
      ):(<LeaveApplicationBodyDiv>
        <Div style={{ justifyContent: "center", alignContent: "center" }}>
            <SlCalender size={300} />
          </Div>
          <Div style={{ justifyContent: "center", alignContent: "center" }}>
            <p>There are no past records of any leave Transactions made by you</p>
          </Div>
      </LeaveApplicationBodyDiv>)
}

export default LeaveGranted