import React from "react";
import { LeaveHistoryBody, Table, Td, Th, Tr } from "./style";
import { Div } from "../../../../styled/style";
import { SlCalender } from "react-icons/sl";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useQuery } from "@tanstack/react-query";
import { getHistoryLeave } from "../../../../utils/LeaveApi";
import { formateDate } from "../../../../utils/utils";
import { Spinner } from "../../../../components/Spinner";

function LeaveHistory() {
  const auth = useSelector((state: RootState) => state.auth);
  const { data: leaves, isLoading } = useQuery({
    queryFn: () => getHistoryLeave(auth.jwt),
    queryKey: ["leave"],
  });

  if (isLoading) {
    return <Spinner/>;
  }

  return leaves !== undefined && leaves.length > 0 ? (
    <LeaveHistoryBody style={{width:'100%'}}>
    <Table style={{width:'100%'}}>
      <Tr>
        <Th>contact details</Th>
        <Th>applied on</Th>
        <Th>from</Th>
        <Th>to date</Th>
        <Th>status</Th>
      </Tr>
      {leaves.map((el) => (
        <Tr>
          <Td>{el.contactDetails}</Td>
          <Td>{formateDate(new Date(el.createdAt))}</Td>
          <Td>{formateDate(new Date(el.fromDate))}</Td>
          <Td>{formateDate(new Date(el.toDate))}</Td>
          <Td>{el.status === 0?<p>pending</p>:el.status === 1?<p>accepted</p>:<p>rejected</p>}</Td>
        </Tr>
      ))}
    </Table>
  </LeaveHistoryBody>
  ):(
    <LeaveHistoryBody>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <SlCalender size={300} />
      </Div>
      <Div style={{ justifyContent: "center", alignContent: "center" }}>
        <p>There are no past records of any leave transactions made by you</p>
      </Div>
    </LeaveHistoryBody>
  );
}

export default LeaveHistory;
