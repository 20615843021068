import React from "react";
import { Div } from "../../../styled/style";
import { Navbar } from "../../../components/Navbar";
import { Footer } from "../../../components/Footer";
import EmployeeList from "../components/EmployeeList";
import { Header } from "../../../components/Header";

function Employee() {
  return (
    <Div style={{ flexDirection: "column" }}>
      <Header />
      <Navbar heading="Employee's List" />
      <div style={{ display: "flex" }}>
        <EmployeeList/>
      </div>
      <Footer />
    </Div>
  );
}

export default Employee;
