import axios from "axios";
import { connection } from "./utils";

interface TrackerData {
  id: string | null;
  personalDetails: {
    I: {
      name: string;
      DOB: string;
    };
    spouse: {
      name: string;
      DOB: string;
    };
    child:
      | {
          name: string;
          DOB: string;
        }[]
      | [];
    pincode: string;
    country: string;
    currentAddress: string;
    permanentAddress: string;
    phoneNumber: string;
    emailId: string;
  };
  professionalDetails: {
    company: string[] | [];
  };
  educationDetails: {
    tenth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    twelfth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    graduation:
      | {
          stream: string;
          passingPercentage: string;
          passingYear: string;
          college: string;
        }[]
      | [];
  };
  references:
    | {
        name: string;
        phoneNumber: string;
      }[]
    | [];
  identity: {
    uanNumber: string;
    panNumber: string;
    passport: string;
    aadharNumber: string;
  };
  bankAccountDetails: {
    bankName: string;
    address: string;
    accountNumber: string;
    ifscCode: string;
    accountHolderName: string;
  };
}

interface trackerDataResponse {
  id: string;
  personalDetails: {
    I: {
      name: string;
      DOB: string;
    };
    spouse: {
      name: string;
      DOB: string;
    };
    child:
      | {
          name: string;
          DOB: string;
          sex: string;
        }[]
      | [];
    pincode: string;
    country: string;
    currentAddress: string;
    permanentAddress: string;
    phoneNumber: string;
    emailId: string;
  };
  professionalDetails: {
    company: string[] | [];
  };
  educationDetails: {
    tenth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    twelfth: {
      school: string;
      passingPercentage: string;
      passingYear: string;
    };
    graduation:
      | {
          stream: string;
          passingPercentage: string;
          passingYear: string;
          college: string;
        }[]
      | [];
  };
  references:
    | {
        name: string;
        phoneNumber: string;
      }[]
    | [];

  identity: {
    uanNumber: string;
    panNumber: string;
    passport: string;
    aadharNumber: string;
  };
  bankAccountDetails: {
    bankName: string;
    address: string;
    accountNumber: string;
    ifscCode: string;
    accountHolderName: string;
  };
  createdAt: string;
  updatedAt: string;
}
export const putTracker = async (
  data: TrackerData
): Promise<trackerDataResponse> => {
  const putData = await axios.put(
    `${connection.live}${connection.urls.updateTrackerForm}`,
    data
  );
  return putData.data;
};

export const getTrackerRequest = async (
  jwt: string
): Promise<trackerDataResponse[]> => {
  const getData = await axios.get(
    `${connection.live}${connection.urls.superAdmin.getTrackerRequest}`,
    {
      headers: {
        Authorization: jwt,
      },
    }
  );
  return getData.data;
};
